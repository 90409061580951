export const checkOrgAndNotLoggedIn = (orgArray = [], isLogged = false) => {
  return orgArray.includes(process.env.REACT_APP_COD_ORGANIZACION) && !isLogged;
};

export const checkOrg = (orgArray = []) => {
  return orgArray.includes(process.env.REACT_APP_COD_ORGANIZACION);
};

export const oneLineStyles = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
};
