export const formatDate = (dateString) => {
  if (dateString.includes("T")) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
    return formattedDate;
  } else {
    const [day, month, year, hours, minutes] = dateString.split(/[\/ :]/);
    const dateObj = new Date(year, month - 1, day, hours, minutes);

    // Paso 2: Formatear la fecha en el formato deseado "dd/MM/yyyy HH:mm"
    const formattedDate = `${("0" + day).slice(-2)}/${("0" + month).slice(
      -2
    )}/${year} ${("0" + hours).slice(-2)}:${("0" + minutes).slice(-2)}`;

    return formattedDate;
  }
};
