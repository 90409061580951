import React from "react";

const GreenBall = ({ size }) => {
  return (
    <svg
      width={size ?? "70"}
      height={size ?? "70"}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35" cy="35" r="35" fill="url(#paint0_radial_521_2172)" />
      <defs>
        <radialGradient
          id="paint0_radial_521_2172"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35 35) rotate(90) scale(35)"
        >
          <stop offset="0.045" stopColor="#F3FFC1" />
          <stop offset="0.44" stopColor="#93F259" />
          <stop offset="1" stopColor="#05E889" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default GreenBall;
