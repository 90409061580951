export const estilosProvNew = {
  11: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  12: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  13: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  18: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  20: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  23: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  41: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  42: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  43: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  48: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  50: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
  53: {
    color: {
      0: {
        colorFrom: "#EC184B",
        colorTo: "#FFFFFF",
      },
      1: {
        colorFrom: "#00AEEE",
        colorTo: "#FFFFFF",
      },
      4: {
        colorFrom: "#FAE91E",
        colorTo: "#FFFFFF",
      },
      3: {
        colorFrom: "#5601B6",
        colorTo: "#FFFFFF",
      },
      raspadita: {
        colorFrom: "#000000",
        colorTo: "#FFFFFF",
      },
    },
  },
};
