import React from "react";
import { Animated } from "react-animated-css";

export const OpenAndCloseEye = ({ passVisible, styles }) => {
  return (
    <div style={{ position: "relative"}}>
      <Animated
        animationIn="fadeIn"
        animationInDuration={200}
        animationOutDuration={200}
        animateOnMount={false}
        isVisible={!passVisible}
        style={styles}
      >
        <span
          id="eye"
          data-width="20px"
          className="iconify op-text"
          data-icon="mdi:eye-off"
          style={{ color: "#9b44ff" }}
          data-inline="false"
        ></span>
      </Animated>
      <Animated
        animationIn="fadeIn"
        animationInDuration={200}
        animationOutDuration={200}
        animateOnMount={false}
        isVisible={passVisible}
        style={styles}
      >
        <span
          id="eye"
          data-width="20px"
          className="iconify op-text"
          style={{ color: "#9b44ff" }}
          data-icon="mdi:eye"
          data-inline="false"
        ></span>
      </Animated>
    </div>
  );
};
