import React from "react";
import styled from "styled-components";
import ButtonSalta from "./ButtonSalta";

const smallBtn = { fontSize: ".76rem", padding: "5px 12px" };

const InstallAppSalta = (props) => {
  return (
    <DivContainer>
      <span style={{ color: "white", fontSize: ".76rem" }}>
        ¿Desea añadir este sitio web a la pantalla de aplicaciones?
      </span>
      <div style={{ display: "flex", gap: "10px" }}>
        <ButtonSalta
          variant="primary"
          style={smallBtn}
          onClick={() => props.installApp()}
        >
          Instalar
        </ButtonSalta>
        <ButtonSalta
          variant="danger"
          style={smallBtn}
          onClick={() => props.updateInstallButton(false)}
        >
          Cancelar
        </ButtonSalta>
      </div>
    </DivContainer>
  );
};

export default InstallAppSalta;

const DivContainer = styled.div`
  width: 100%;
  background-color: #111827;
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    justify-content: center;
    span {
      text-align: center;
    }
  }
`;
