import React from "react";
import { Animated } from "react-animated-css";
import QRCode from "qrcode.react";
import NumberFormat from "react-number-format";
import QRModalSalta from "../../components/salta/QRModalSalta";

const QrModal = ({
  error,
  token,
  nroQR,
  solicitud,
  cargandoQR,
  clickGenerarQR,
  downloadImg,
}) => {
  // console.log("token");
  // console.log(token);
  // console.log(typeof token);
  
  if (["23", "53"].includes(process.env.REACT_APP_COD_ORGANIZACION))
    return (
      <QRModalSalta
        error={error}
        token={token}
        nroQR={nroQR}
        solicitud={solicitud}
        cargandoQR={cargandoQR}
        clickGenerarQR={clickGenerarQR}
        downloadImg={downloadImg}
      />
    );

  return (
    <div
      style={{ position: "fixed", top: "20%", overflowY: "scroll" }}
      className="modal fade pb-5"
      id="qrModal"
      role="dialog"
      aria-labelledby="qrModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header bg-light">
            <h5 className="modal-title" id="qrModalModalLabel">
              QR Identificación
            </h5>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body text-center mb-2 text-small">
            <div className="text-center">
              {error === false && (
                <div className="op-text" style={{ fontSize: "12px" }}>
                  Válido por 15 minutos
                </div>
              )}

              <div
                id="download"
                className="pt-2 pb-2"
                style={{ backgroundColor: "#FFFFFF" }}
              >
                <div className="mt-2">
                  {token !== "" && error === false && (
                    <Animated animationIn="zoomIn">
                      <QRCode className="mb-1" value={token} />
                      <div className="m-bold">
                        <h6 className="mb-0">{nroQR}</h6>
                      </div>
                    </Animated>
                  )}
                </div>

                {cargandoQR && (
                  <div>
                    <div className="animate-flicker">
                      <b>Cargando QR ...</b>
                    </div>
                    <br></br>
                  </div>
                )}

                {error === false && (
                  <div>
                    <span className="op-text" style={{ fontSize: "12px" }}>
                      Autorizo a retirar de mi cuenta la suma de:
                    </span>
                    <h5 className="text-success m-bold">
                      <NumberFormat
                        value={solicitud.importe}
                        displayType={"text"}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={"$ "}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        isNumericString={true}
                      />
                    </h5>
                    <p>
                      {/* en la <b className="m-bold">Agencia n°{agenciaRetiros!=null?agenciaRetiros.descripcion:""}</b> 
                                            <br/>
                                            <span className="op-text" style={{fontSize:"12px"}}>
                                                {agenciaRetiros!=null?agenciaRetiros.direccion:""} - {agenciaRetiros!=null?agenciaRetiros.localidad:""}
                                            </span> */}
                      en una <span className="m-bold">Agencia oficial</span>
                    </p>
                  </div>
                )}
              </div>

              {error === false && (
                <button
                  className={
                    "btn btn-success btn-retiro bg-btn-extraccion-prov-" +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-dismiss="modal"
                >
                  VOLVER
                </button>
              )}

              <br />

              {error === false && (
                <button
                  style={{
                    borderRadius: "50%",
                    width: "38px",
                    height: "38px",
                  }}
                  className="btn  mt-3 pos-rel bg-prov-42"
                  onClick={downloadImg}
                >
                  <span
                    style={{ position: "absolute", top: "5px", left: "5px" }}
                    className="iconify"
                    data-icon="feather:download"
                    data-inline="false"
                    data-width="25px"
                  ></span>
                </button>
              )}

              {error === true && (
                <div>
                  <h4>Ups. </h4>
                  <div className="text-center pb-2 mt-2">
                    Favor reintente más tarde. Verifique la conexión a internet.
                  </div>
                  <br></br>
                  <button className="btn btn-info" onClick={clickGenerarQR}>
                    Reintentar
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrModal;
