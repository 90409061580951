import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { useMediaQuery } from "react-responsive";
import coinsBackgroundImg from "../../img/coinsBackground.png";
import { estilosProv } from "../../configProvincias";
import NumberFormat from "react-number-format";
import ConfettiCanvas from "./ConfettiCanvas";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; transform: scale(1); }
  to { opacity: 0; transform: scale(0.95); }
`;

const slideUp = keyframes`
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const slideDown = keyframes`
  from { transform: translateY(0); opacity: 1; }
  to { transform: translateY(100%); opacity: 0; }
`;

const ModalOverlay = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  animation: ${({ isOpen }) => (isOpen ? fadeIn : "none")} 0.3s ease-in-out;
`;

const CoinsImg = styled.img`
  position: absolute;
  width: 70%;
  opacity: 0.6;
  pointer-events: none;
`;

const StickModal = styled.span`
  width: 45px;
  height: 5px;
  border-radius: 15px;
  background: #79747e;
  position: absolute;
  top: 13px;
`;

const ModalContent = styled.div`
  background: white;
  position: relative;
  border-radius: 20px;
  padding: 40px 20px;
  border: 0.5px solid #d9d9d9;
  overflow: hidden;
  width: ${({ isMobile }) => (isMobile ? "98%" : "40%")};
  max-width: 500px;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ isMobile, isClosing }) =>
    isMobile
      ? css`
          position: absolute;
          /* bottom: 55px; */
          bottom: 0px;
          border-radius: 28px 28px 0 0;
          animation: ${isClosing ? slideDown : slideUp} 0.3s ease-in-out;
        `
      : css`
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
          animation: ${isClosing ? fadeOut : fadeIn} 0.3s ease-in-out;
        `}
`;

export const AwardModalSalta = ({ isOpen, onClose, awardData = [] }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);
  const [isClosing, setIsClosing] = useState(false);

  const stylesByOrg = estilosProv[process.env.REACT_APP_COD_ORGANIZACION];

  const handleTouchStart = (e) => {
    if (isMobile) setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (isMobile) setTouchEndY(e.touches[0].clientY);
  };

  const handleTouchEnd = () => {
    if (isMobile && touchStartY && touchEndY && touchEndY - touchStartY > 70) {
      handleClose(); // Close modal on swipe down (now 70px threshold)
    }
    setTouchStartY(null);
    setTouchEndY(null);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  return (
    <ModalOverlay isOpen={isOpen} onClick={handleClose}>
      <ConfettiCanvas />
      <ModalContent
        isMobile={isMobile}
        isClosing={isClosing}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={isMobile ? handleTouchStart : undefined}
        onTouchMove={isMobile ? handleTouchMove : undefined}
        onTouchEnd={isMobile ? handleTouchEnd : undefined}
      >
        {isMobile ? (
          <StickModal />
        ) : (
          <button
            type="button"
            className="close"
            style={{ position: "absolute", top: "6px", right: "10px" }}
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
        <CoinsImg src={coinsBackgroundImg} alt="Modal Image" />
        <h2
          style={{ color: "#08E12B", textAlign: "center" }}
          className="m-bold"
        >
          ¡FELICIDADES!
        </h2>
        <div
          style={{ margin: "20px 0", maxHeight: "250px", overflowY: "scroll" }}
        >
          {awardData.map((item) => (
            <p
              style={{ margin: "5px 0", fontSize: "1rem", textAlign: "center" }}
              className="m-bold"
              key={item.id}
            >
              Recibiste:{" "}
              <span style={{ color: stylesByOrg.success }}>
                {item.tipoBonificacion?.nombre}
              </span>{" "}
              {item.importe && (
                <>
                  con un monto de{" "}
                  <NumberFormat
                    value={item.importe.toString()}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                    style={{ color: stylesByOrg.success, display: "inline" }}
                  />
                </>
              )}
            </p>
          ))}
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        ></div>
      </ModalContent>
    </ModalOverlay>
  );
};
