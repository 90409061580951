import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "jquery-confirm";
import logoRetiro from "../img/icons/retiro-icon-color.png";
import * as htmlToImage from "html-to-image";
import downloadjs from "downloadjs";
import { instalaciones } from "../instalaciones.js";
import SolicitudEnCurso from "../tools/identificadorQRCliente/SolicitudEnCurso.jsx";
import PaginaSolicitudRetiro, {
  DivColRetiro,
  H4Retiro,
  H5Saldo,
  H6SaldoTexto,
} from "../tools/identificadorQRCliente/PaginaSolicitudRetiro.jsx";
import QrModal from "../tools/identificadorQRCliente/QrModal.jsx";
import PaginaSinConexion from "../tools/identificadorQRCliente/PaginaSinConexion.jsx";
import { DivGeneral } from "./consultaBilletera.jsx";
import EfectoCargando, { DivCargando } from "../tools/EfectoCargando.jsx";
import NumberFormat from "react-number-format";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import styled from "styled-components";
import axios from "axios";
import { estilosProv } from "../configProvincias.js";
import DepositMoneySalta from "../components/salta/DepositMoneySalta.jsx";
import { checkOrg, checkOrgAndNotLoggedIn } from "../utils/generalUtils";

const RetiroDinero = ({
  estadoPlataforma,
  mensajeErrorWS,
  mensajeErrorGeneral,
  cerrarSesion,
  history,
  logCurrentPage,
}) => {
  //--------------------------------HOOKS DE ESTADO ------------------------------------
  const [agenciaRetiros, setagenciaRetiros] = useState({
    codigo: "",
    descripcion: "",
    direccion: "",
    localidad: "",
  });

  const [montoConDecimales, setmontoConDecimales] = useState(undefined);
  const [preferenceId, setpreferenceId] = useState(undefined);
  const [propDeposito, setpropDeposito] = useState(undefined);
  const colorProv = estilosProv[process.env.REACT_APP_COD_ORGANIZACION].fondo;
  const [size, setSize] = useState(window.innerWidth);
  const [height, setheight] = useState(window.innerHeight);

  const isOrgAndNotLoggedIn = checkOrgAndNotLoggedIn(
    ["53", "23"],
    estadoPlataforma.logueado
  );
  const isOrg = checkOrg(["53", "23"]);

  //initMercadoPago("APP_USR-b06e4bdb-2b48-4c41-9a70-405623165081");

  initMercadoPago("APP_USR-34fb6798-6bdc-4ccd-89e0-99f780eda125", {
    locale: "es-AR",
  });

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      setSize(newWidth);
      setheight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [size, height]);

  useEffect(() => {
    if (!isOrg) return;

    iniciarlizarRetiro();
    getPropDeposito();
  }, []);

  useEffect(() => {
    if (isOrg) {
      if (estadoPlataforma.logueado) {
        iniciarlizarRetiro();
      }
      getPropDeposito();
    }
  }, [estadoPlataforma.logueado]);

  /**
   * Se da un seteo inicial a los diferentes hooks de estado.
   * Se realiza una peticion fetch:
   * si la peticion es exitosa se setean las @constant saldo
   * @constant saldoTotal
   * @constant montoMaximoRetiroDirecto
   * @constant agenciaRetiros
   * @constant solicitudEnCurso
   * @constant solicitud
   * en caso de error se llama a la @function mensajeErrorWS
   */
  const iniciarlizarRetiro = () => {
    if (isOrgAndNotLoggedIn) return;

    setagenciaRetiros({});

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_INIT_RETIRO;

    let cerrarSesion = false;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          let aSolicitud = json.retiro;
          if (aSolicitud == null) {
            aSolicitud = { monto: "" };
          }
          setagenciaRetiros(json.agAmiga);
        } else {
          if (json.status === "error") {
            if (cerrarSesion) {
              mensajeErrorWS("Retiros", json.errores, cerrarSesion);
            } else {
              mensajeErrorWS("Retiros", json.errores);
            }
          } else {
          }
        }
      })
      .catch((error) => {
        //mensajeErrorGeneral();
      });
  };

  const getHeadersForPropsDeposito = () => {
    if (isOrg) return {};

    return { Authorization: "Bearer " + estadoPlataforma.userToken };
  };

  const getPropDeposito = () => {
    // if (isOrgAndNotLoggedIn) return;

    const restUrl = isOrg
      ? process.env.REACT_APP_WS_GET_PROPS_DEPOSIT_TRANSFER
      : process.env.REACT_APP_WS_GET_PROPS_DEPOSITO;

    const forOrg =
      "?codigoOrganizacion=" + process.env.REACT_APP_COD_ORGANIZACION;

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND + restUrl + (isOrg ? forOrg : "");

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        ...getHeadersForPropsDeposito(),
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        //console.log(json);

        setpropDeposito(json);
      })
      .catch((error) => {
        //mensajeErrorGeneral();
      });
  };

  useEffect(() => {
    //console.log(propDeposito);
  }, [propDeposito]);

  const cumpleRequisito = () => {
    return (
      montoConDecimales >= propDeposito?.minTrans &&
      montoConDecimales <= propDeposito?.maxDia &&
      montoConDecimales % propDeposito?.mulTrans === 0
    );
  };

  /**
   *
   * @param {event} e handler que a partir de un eveto verifica si ese evento
   * es el haber tocado la tecla enter y en caso positivo se efecuta la @function blur
   * sobre el target del evento
   */
  const hanldeInputBlur = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  /**
   * A traves de jquery se generara un modal para cancelar una solicitud de retiro
   * en caso de presionar el boton Aceptar se ejecutara la @function cancelarSolicitudRetiro
   */

  /**
   * Se realiza un comprobacion con el estado grabandoSolicitud en caso que sea falso, lo setea en true
   * con la @function setgrabandoSolicitud y luego se llama a la @function grabarSolicitudDeRetiro
   */
  const clickGenerarSolicitud = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GENERAR_ORDEN_CARGA;
    axios
      .post(
        url,
        {
          identificacion: estadoPlataforma.apellido,
          importeCarga: montoConDecimales,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + estadoPlataforma.userToken,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          return res.data;
        }
      })
      .then((data) => setpreferenceId(data.preferenceId))
      .catch((err) => {
        console.error(err);
      });
  };

  const formatearNumeroString = (string) => {
    let array = string?.split("").reverse();
    let result = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (index > 0 && index % 3 === 0) {
        result.push(".");
      }
      result.push(element);
      //console.log(result);
    }

    return result.reverse().join("");
  };

  if (["23", "53"].includes(process.env.REACT_APP_COD_ORGANIZACION))
    return (
      <DepositMoneySalta
        estadoPlataforma={estadoPlataforma}
        montoConDecimales={montoConDecimales}
        setmontoConDecimales={setmontoConDecimales}
        propDeposito={propDeposito}
        formatearNumeroString={formatearNumeroString}
        cumpleRequisito={cumpleRequisito}
        mensajeErrorWS={mensajeErrorWS}
      />
    );

  return (
    <DivGeneral className="pageFlow">
      <Animated
        animationIn="fadeIn"
        hidden={estadoPlataforma.estado !== "PLATAFORMA"}
      >
        <div className="container">
          <DivRow className="row justify-content-center">
            <div className="col-6">
              <div>
                <h6>
                  {" "}
                  Monto a <span className="m-bold">Ingresar</span>{" "}
                </h6>
                <div
                  className="p-3 rounded w-92 mx-auto"
                  style={{ border: "1px solid grey" }}
                >
                  <b>
                    <span style={{ fontSize: "20px" }}>$ </span>
                  </b>
                  <NumberFormat
                    type="text"
                    autoComplete="off"
                    maxLength={13}
                    isNumericString={true}
                    onChange={hanldeInputBlur}
                    decimalScale={2}
                    id="idMontoSolicitud"
                    name="fMontoSolicitud"
                    style={{
                      width: "75%",
                      border: "none",
                      fontWeight: "bold",
                      textAlign: "center",
                      height: "32px",
                    }}
                    displayType={"input"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    onValueChange={(values) => {
                      setmontoConDecimales(values.floatValue);
                      setpreferenceId(undefined);
                    }}
                  />
                </div>
              </div>
            </div>
          </DivRow>

          {propDeposito !== undefined && (
            <DivRow className="row justify-content-center">
              <div
                className="col-12"
                style={
                  cumpleRequisito()
                    ? { fontSize: "12px" }
                    : { fontSize: "12px", color: "red" }
                }
              >
                <h6>
                  Desde: ${" "}
                  {formatearNumeroString(propDeposito.minTrans.toString())}{" "}
                  Hasta: ${" "}
                  {formatearNumeroString(propDeposito.maxDia.toString())}
                </h6>
              </div>
              <div
                className="col-12"
                style={
                  cumpleRequisito()
                    ? { fontSize: "12px" }
                    : { fontSize: "12px", color: "red" }
                }
              >
                Multiplo: $ {propDeposito.mulTrans}
              </div>
            </DivRow>
          )}
          <DivRow className="row justify-content-center">
            {preferenceId == undefined ? (
              <div className="col-12">
                <button
                  disabled={!cumpleRequisito()}
                  id="botonGenerarSolicitud"
                  className={
                    "btn btn-success p-2 pl-3 pr-3 bg-btn-extraccion-prov-" +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  onClick={() => clickGenerarSolicitud()}
                >
                  <h5 className="mb-0">Ingresar</h5>
                </button>
              </div>
            ) : (
              <div className={size > 820 ? "col-6" : "col-12"}>
                <div
                  id="wallet_container"
                  onClickCapture={() => {
                    setpreferenceId(undefined);
                    setmontoConDecimales(undefined);
                    setInterval(() => {
                      window.refreshSaldo();
                      window.refreshMensajeria();
                    }, 10000);
                  }}
                >
                  <Wallet
                    initialization={{
                      preferenceId: preferenceId,
                    }}
                    customization={{ texts: { valueProp: "smart_option" } }}
                  />
                </div>
              </div>
            )}
          </DivRow>
        </div>
      </Animated>
    </DivGeneral>
  );
};

export default withRouter(RetiroDinero);

const DivRow = styled.div`
  text-align: center;
  margin: 2vh;
  align-items: self-end;
`;

const DivModal = styled.div``;

const DivContainerModal = styled.div``;
