import styled from "styled-components";

const bgVariants = {
  success: "#08E12B",
  danger: "#DC2D14",
  light: "#fff",
  primary: "#A353FF",
};

const hoverVariants = {
  success: "#06C124",
  danger: "#B82410",
  light: "#eeeded",
  primary: "#9041eb",
};

const colorVariants = {
  success: "#fff",
  danger: "#fff",
  light: "#6750A4",
  primary: "#fff",
};

const ButtonSalta = styled.button`
  background: ${({ variant }) => bgVariants[variant] || bgVariants.success};
  color: ${({ variant }) => colorVariants[variant] || colorVariants.success};
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 0.1px;
  text-align: center;

  padding: 10px 24px;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;

  &:hover {
    ${({ disabled, variant }) =>
      !disabled &&
      `background: ${
        hoverVariants[variant] || hoverVariants.success
      }; transform: scale(1.05);`}
  }

  &:active {
    ${({ disabled }) => !disabled && "transform: scale(0.95);"}
  }

  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export default ButtonSalta;

export const IconButtonSalta = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }

  &:active {
    transform: scale(1);
  }

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;
