import React from "react";
const RedBall = ({ size }) => {
  return (
    <svg
      width={size ?? "70"}
      height={size ?? "70"}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.11 42.84C73.44 24 61.68 5.21998 42.84 0.899978C24 -3.43002 5.21999 8.32998 0.899993 27.17C-3.43001 46.01 8.32999 64.79 27.17 69.11C46.01 73.44 64.79 61.68 69.11 42.84Z"
        fill="url(#paint0_radial_1085_1893)"
      />
      <path
        d="M35.07 57.54C51.5 57.54 64.81 44.66 64.81 28.77C64.81 12.88 51.5 0 35.07 0C18.64 0 5.32999 12.88 5.32999 28.77C5.32999 44.66 18.65 57.54 35.07 57.54Z"
        fill="url(#paint1_radial_1085_1893)"
      />
      <path
        d="M35.04 51.27C47.1 51.27 56.87 39.79 56.87 25.63C56.87 11.47 47.1 0 35.04 0C22.98 0 13.2 11.48 13.2 25.64C13.2 39.8 22.98 51.28 35.03 51.28L35.04 51.27Z"
        fill="url(#paint2_radial_1085_1893)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1085_1893"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.32 32.28) scale(35)"
        >
          <stop stopColor="#F4A7A8" />
          <stop offset="0.61" stopColor="#B71F27" />
          <stop offset="0.82" stopColor="#9A1917" />
          <stop offset="0.92" stopColor="#8A160E" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1085_1893"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.268 28.3141) scale(27.95 31.03)"
        >
          <stop stopColor="#EE7566" />
          <stop offset="0.84" stopColor="#AE1E25" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1085_1893"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.7936 23.064) scale(20.52 27.65)"
        >
          <stop stopColor="#E9500E" />
          <stop offset="0.03" stopColor="#E74D0D" />
          <stop offset="0.44" stopColor="#D02A0D" />
          <stop offset="0.78" stopColor="#C2150D" />
          <stop offset="1" stopColor="#BD0E0D" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default RedBall;
