import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FullModal from "./FullModal";
import InputSalta from "./InputSalta";
import { Form } from "reactstrap";
import ButtonSalta from "./ButtonSalta";
import logo from "../../../src/img/LOGO_SPLASH_53.png";
import ticketExample from "../../../src/img/cuponExample.jpg";

const ModalCheckTicketSalta = (props) => {
  const [ticketNumber, setTicketNumber] = useState("");
  const [firstSubmitted, setFirstSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errors, setErrors] = useState({
    ticketNumber: "",
  });
  const [ticketData, setTicketData] = useState(null);

  useEffect(() => {
    let allErrors = { ...errors };
    allErrors = handleCheckErrors("ticketNumber", ticketNumber, allErrors);
    setErrors({ ...errors, ...allErrors });
  }, []);

  useEffect(() => {
    setErrorMessage("");
    setTicketData(null);
  }, [ticketNumber]);

  const hasErrors = Object.values(errors).some((error) => error !== "");

  const handleCheckErrors = (fieldId, value, updateOwnErrorObj = undefined) => {
    let finalValue = "";
    const allErrors = updateOwnErrorObj
      ? { ...updateOwnErrorObj }
      : { ...errors };

    if (fieldId === "ticketNumber") {
      value.length !== 15 && (finalValue = "El cupón debe tener 13 dígitos");
      !value && (finalValue = "Debes ingresar un valor");
      allErrors.ticketNumber = finalValue;
    }

    !updateOwnErrorObj && setErrors({ ...errors, ...allErrors });
    if (updateOwnErrorObj) return { ...allErrors };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFirstSubmitted(true);

    if (hasErrors) return;

    setLoading(true);
    setErrorMessage("");
    setTicketData(null);

    let closeSession = false;

    const fullValue = ticketNumber.replace(/-/g, "");
    const org = process.env.REACT_APP_COD_ORGANIZACION;

    let url = `${process.env.REACT_APP_URL_BASE_BACKEND}${process.env.REACT_APP_WS_CHECK_TICKET_RETAIL}?cup=${fullValue}&org=${org}`;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((data) => {
        setLoading(false);
        if (closeSession) {
          setErrorMessage("Su sesión se ha cerrado, vuelva a ingresar.");
          return;
        }
        if (data.titulo) {
          setTicketData(data);
        } else {
          setErrorMessage("Ocurrió un error.");
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage("Ocurrió un error.");
      });
  };

  const formatearNumeroString = (string) => {
    let array = string?.split("").reverse();
    let result = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (index > 0 && index % 3 === 0) {
        result.push(".");
      }
      result.push(element);
    }
    return result.reverse().join("");
  };

  return (
    <FullModal open={props.open} handleClose={props.handleClose}>
      <>
        <Image src={logo} alt="logo" />
        <h3 style={{ textAlign: "center" }} className="m-bold">
          Consultá acá si tu cupón tiene premio
        </h3>

        <Container>
          <Form
            autoComplete="off"
            id="checkTickets"
            onSubmit={handleSubmit}
            noValidate
            style={{
              gap: "20px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <img src={ticketExample} alt="cupón ejemplo" width={"100%"} />
            <div
              style={{
                display: "flex",
                gap: "20px",
                alignItems: "center",
                flexWrap: props.size < 500 ? "wrap" : "unset",
              }}
            >
              <div style={{ width: "100%" }}>
                <InputSalta
                  id={"ticketNumber"}
                  placeholder={"12345-12-123456"}
                  value={ticketNumber}
                  setValue={setTicketNumber}
                  errorMessage={firstSubmitted ? errors.ticketNumber : ""}
                  handleCheckErrors={handleCheckErrors}
                  isTicket
                />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: props.size < 500 ? "100%" : "auto",
                }}
              >
                <ButtonSalta
                  variant="primary"
                  type="submit"
                  style={{ width: "160px" }}
                  disabled={loading}
                >
                  {loading ? "CARGANDO..." : "CONSULTAR"}
                </ButtonSalta>
              </div>
            </div>
            {/* Error Request*/}
            {errorMessage && (
              <DivError>
                <p className="m-bold" style={{ margin: 0, color: "black" }}>
                  {errorMessage}
                </p>
              </DivError>
            )}
            {/* Error Ticket*/}
            {!loading && ticketData && !ticketData.nSorte && (
              <DivError>
                <p className="m-bold" style={{ margin: 0, color: "black" }}>
                  {ticketData.titulo}
                </p>
              </DivError>
            )}
            {/* ticket data */}
            {!loading && ticketData && ticketData.nSorte && (
              <div style={{ margin: "20px 0", color: "black" }}>
                <p
                  className="m-bold"
                  style={{ margin: 0, textAlign: "center" }}
                >
                  {"Datos del cupón"}
                </p>
                <span
                  style={{
                    width: "100%",
                    borderBottom: "1px solid #ccc",
                    display: "block",
                    margin: "10px 0",
                  }}
                ></span>
                <div>
                  <DivInfo>
                    <PInfo>Número de Sorteo</PInfo>
                    <PValue>{ticketData.nSorte}</PValue>
                  </DivInfo>
                  <DivInfo>
                    <PInfo>Fecha de Sorteo</PInfo>
                    <PValue>{ticketData.fSorte}</PValue>
                  </DivInfo>
                  <DivInfo>
                    <PInfo>Punto de Venta</PInfo>
                    <PValue>{ticketData.puntoDeVenta}</PValue>
                  </DivInfo>
                  <DivInfo>
                    <PInfo>Pagado en</PInfo>
                    <PValue>{ticketData.pagadoEn}</PValue>
                  </DivInfo>
                  <DivInfo>
                    <PInfo>Fecha de Pago</PInfo>
                    <PValue>{ticketData.fechaDePago}</PValue>
                  </DivInfo>
                  {ticketData.fechaDeCaducidad && (
                    <DivInfo>
                      <PInfo>Fecha de Caducidad</PInfo>
                      <PValue>{ticketData.fechaDeCaducidad}</PValue>
                    </DivInfo>
                  )}
                  <DivInfo>
                    <PInfo>Importe Ganado</PInfo>
                    <PValue>
                      $
                      {formatearNumeroString(
                        ticketData.importeGanado.toString() ?? ""
                      )}
                    </PValue>
                  </DivInfo>
                  {ticketData.premioPuntoDeVenta && (
                    <DivInfo>
                      <PInfo>Premio Punto de Venta</PInfo>
                      <PValue>
                        $
                        {formatearNumeroString(
                          ticketData.premioPuntoDeVenta.toString() ?? ""
                        )}
                      </PValue>
                    </DivInfo>
                  )}
                  <DivInfo>
                    <PInfo>Retenciones</PInfo>
                    <PValue>
                      $
                      {formatearNumeroString(
                        ticketData.retenciones.toString() ?? ""
                      )}
                    </PValue>
                  </DivInfo>
                  <DivInfo>
                    <PInfo>Importe A Cobrar</PInfo>
                    <PValue>
                      $
                      {formatearNumeroString(
                        ticketData.importeACobrar.toString() ?? ""
                      )}
                    </PValue>
                  </DivInfo>
                </div>
              </div>
            )}
          </Form>
        </Container>
      </>
    </FullModal>
  );
};

export default ModalCheckTicketSalta;

const Container = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  max-width: 600px;

  background: white;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 8px 8px 7px 2px rgba(40, 38, 38, 0.29);
`;

const Image = styled.img`
  width: 200px;
  @media (max-width: 620px) {
    width: 150px;
  }
`;

const DivError = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  background: #fef7ff;
  border-radius: 6px;
  padding: 15px;
  text-align: center;
`;

const DivInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 15px;
`;

const PValue = styled.p`
  margin: 0;
  text-align: end;
`;

const PInfo = styled.p`
  margin: 0;
`;
