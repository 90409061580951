import React from "react";
const WhiteBall = ({ size }) => {
  return (
    <svg
      width={size ?? "90"}
      height={size ?? "90"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15_2647)">
        <path
          d="M39.4979 24.4779C41.9717 13.7114 35.2491 2.97801 24.4826 0.504237C13.7161 -1.96953 2.98271 4.75305 0.508943 15.5196C-1.96483 26.2861 4.75776 37.0194 15.5243 39.4932C26.2908 41.967 37.0241 35.2444 39.4979 24.4779Z"
          fill="url(#paint0_radial_15_2647)"
        />
        <path
          d="M37.7878 16.3057C37.5534 24.3877 29.3981 30.7069 19.5727 30.4236C9.74728 30.1404 1.97289 23.3573 2.2073 15.2753C2.44658 7.19325 10.6019 0.874117 20.4224 1.15735C30.2478 1.44059 38.0222 8.22365 37.7878 16.3057Z"
          fill="url(#paint1_radial_15_2647)"
        />
        <path
          d="M32.2403 8.40921C32.1231 12.4624 26.5462 15.5878 19.7876 15.3925C13.0289 15.1972 7.64742 11.7495 7.76462 7.70112C7.88182 3.64789 13.4587 0.522511 20.2173 0.717847C26.976 0.913183 32.3575 4.36087 32.2403 8.40921Z"
          fill="url(#paint2_linear_15_2647)"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_15_2647"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.0036 20.0026) scale(20.0024)"
        >
          <stop stopColor="#EAE9EA" />
          <stop offset="0.49" stopColor="#E9E9E8" />
          <stop offset="0.77" stopColor="#E0E0DF" />
          <stop offset="0.92" stopColor="#D0D5DA" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_15_2647"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.3964 22.5662) scale(26.5413)"
        >
          <stop stopColor="#EAE9EA" />
          <stop offset="0.49" stopColor="#E9E9E8" />
          <stop offset="0.77" stopColor="#E0E0DF" />
          <stop offset="0.92" stopColor="#D0D5DA" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_15_2647"
          x1="20.0024"
          y1="7.99413"
          x2="20.232"
          y2="-3.97023"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EBEBEA" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_15_2647">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WhiteBall;
