import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "jquery-confirm";
import logoRetiro from "../img/icons/retiro-icon-color.png";
import * as htmlToImage from "html-to-image";
import downloadjs from "downloadjs";
import NumberFormat from "react-number-format";
import { instalaciones } from "../instalaciones.js";
import SolicitudEnCurso from "../tools/identificadorQRCliente/SolicitudEnCurso.jsx";
import PaginaSolicitudRetiro from "../tools/identificadorQRCliente/PaginaSolicitudRetiro.jsx";
import QrModal from "../tools/identificadorQRCliente/QrModal.jsx";
import PaginaSinConexion from "../tools/identificadorQRCliente/PaginaSinConexion.jsx";
import { DivGeneral } from "./consultaBilletera.jsx";
import { checkOrg, checkOrgAndNotLoggedIn } from "../utils/generalUtils";
import { estilosProv } from "../configProvincias";
import axios from "axios";
import EfectoCargando from "../tools/EfectoCargando";

const RetiroDinero = ({
  estadoPlataforma,
  mensajeErrorWS,
  mensajeErrorGeneral,
  cerrarSesion,
  history,
  logCurrentPage,
}) => {
  //--------------------------------HOOKS DE ESTADO ------------------------------------
  const [agenciaRetiros, setagenciaRetiros] = useState({
    codigo: "",
    descripcion: "",
    direccion: "",
    localidad: "",
  });
  const [solicitud, setsolicitud] = useState({
    monto: "",
    codigo: "",
    descripcion: "",
    direccion: "",
    localidad: "",
    agencia: {},
  });
  const stylesByOrg = estilosProv[process.env.REACT_APP_COD_ORGANIZACION];
  const [errores, seterrores] = useState(undefined);
  const [cambioExitoso, setcambioExitoso] = useState(undefined);
  const [grabandoSolicitud, setgrabandoSolicitud] = useState(false);
  const [Stipo, setStipo] = useState();
  const [visualizarQR, setvisualizarQR] = useState();
  const [disabled, setdisabled] = useState(false);
  const [saldo, setsaldo] = useState("");
  const [cargando, setcargando] = useState("INICIO");
  const [saldoTotal, setsaldoTotal] = useState("");
  const [montoMaximoRetiroDirecto, setmontoMaximoRetiroDirecto] = useState(0);
  const [cargandoQR, setcargandoQR] = useState(false);
  const [token, settoken] = useState("");
  const [nroQR, setnroQR] = useState("");
  const [error, seterror] = useState(false);
  const [montoConDecimales, setmontoConDecimales] = useState(undefined);
  const [inicioOK, setinicioOK] = useState(true);
  const [montoSolicitud, setmontoSolicitud] = useState("");
  const [solicitudGenerada, setsolicitudGenerada] = useState(false);
  const [vigenciaSolicitud, setvigenciaSolicitud] = useState("");
  const [solicitudEnCurso, setsolicitudEnCurso] = useState(false);
  const [ultimoCbu, setUltimoCbu] = useState("");
  const [extractionType, setExtractionType] = useState("");
  const color = process.env.REACT_APP_MANIFEST_COLOR;
  const [isLoadingSolicitud, setisLoadingSolicitud] = useState(false);

  const isOrgAndNotLoggedIn = checkOrgAndNotLoggedIn(
    ["53", "23"],
    estadoPlataforma.logueado
  );

  //----------------------------- HOOK DE EFECTO ---------------------------
  useEffect(() => {
    window.scrollTo(0, 0);
    $(".pageFlow").scrollTop(0);
    logCurrentPage("retirar");

    if (!checkOrg(["53", "23"])) {
      iniciarlizarRetiro();
    }
  }, []);

  useEffect(() => {
    if (checkOrg(["53", "23"])) {
      if (!estadoPlataforma.logueado) {
        setinicioOK(true);
        setisLoadingSolicitud(false);
        setsolicitudEnCurso(false);
      } else {
        iniciarlizarRetiro();
      }
    }
  }, [estadoPlataforma.logueado]);

  /**
   *
   * @param {event} e handler que a partir de un eveto verifica si ese evento
   * es el haber tocado la tecla enter y en caso positivo se efecuta la @function blur
   * sobre el target del evento
   */
  const handleInputBlur = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  /**
   * Se realiza un pedido fetch en caso de que la consulta sea exitosa se llamara a la @function iniciarlizarRetiro
   * y luego se actualizara el saldo con la @function refreshSaldo. en caso de que haya un error se lamaran a las @function seterrores setcambioExitoso y mensajeErrorWS
   *
   */
  const cancelarSolicitudRetiro = () => {
    let parametros = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      agencia: agenciaRetiros.codigo,
      importe: solicitud.importe,
      cancelar: true,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_CANCELAR_RETIRO;

    let closeSession = false;
    let statusCode = "";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          iniciarlizarRetiro();
          refreshSaldo();
        } else if (json.status === "error") {
          if (closeSession) {
            mensajeErrorWS(
              "Cancelo solicitud de retiro",
              json.errores,
              closeSession
            );
          } else {
            seterrores(json.errores);
            setcambioExitoso(false);
            mensajeErrorWS("Canceló solicitud de retiro", json.errores);
          }
        }
      })
      .catch((error) => {
        mensajeErrorGeneral();
      });
  };

  /**
   * A traves de jquery se generara un modal para cancelar una solicitud de retiro
   * en caso de presionar el boton Aceptar se ejecutara la @function cancelarSolicitudRetiro
   */
  const clickCancelarSolicitud = () => {
    $.confirm({
      title: '<b className="op-text">Solicitud de retiro</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<h5 className="text-center mt-4 mb-2">
						¿Desea cancelar la solicitud de retiro?  
					 </h5>`,
      buttons: {
        No: {
          btnClass: "btn btn-danger",
          text: checkOrg(["53", "23"]) ? "Volver" : "Cancelar",
          action: function () {},
        },
        Si: {
          btnClass: "btn btn-success",
          text: checkOrg(["53", "23"]) ? "Confirmar" : "Aceptar",
          action: function () {
            if (solicitud.modoPedidoRetiro !== "TRANSFERENCIA") {
              cancelarSolicitudRetiro();
            } else if (solicitud.modoPedidoRetiro === "TRANSFERENCIA") {
              handleCancelTransfer();
            }
          },
        },
      },
    });
  };

  /**
   * Se realiza un comprobacion con el estado grabandoSolicitud en caso que sea falso, lo setea en true
   * con la @function setgrabandoSolicitud y luego se llama a la @function grabarSolicitudDeRetiro
   */
  const clickGenerarSolicitud = () => {
    if (grabandoSolicitud === false) {
      setgrabandoSolicitud(true);
      grabarSolicitudDeRetiro();
    }
  };

  /*const clickVolver = () => {
    iniciarlizarRetiro();
  };*/

  /**
   * Se setea la @constant stipo en "solicitud"
   * se setea la @constant visualizarQR en true
   * se llama a la @function generarQR
   */
  const clickGenerarQRSolRetiro = () => {
    setStipo("solicitud");
    setvisualizarQR(true);
    generarQR();
  };

  /**
   *verifica que las @prop estadoPlataforma.logueado este en true y que la @constant disabled este en false
   * en caso de ingresar al if se setea la @constant disabled en true y se ejecuta la @function generarQR
   */
  const clickGenerarQR = () => {
    if (estadoPlataforma.logueado && !disabled) {
      setdisabled(true);
      generarQR();
    }
  };

  /**
   * Se realiza una peticion fetch:
   * si la peticion es exitosa se setea el saldo retirable
   * en caso de error se llama a la @function cerrarSesion
   */
  const refreshSaldo = () => {
    if (isOrgAndNotLoggedIn) return;

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GET_SALDO;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion();
          window.location.reload(true);
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setsaldo(json.saldoRetirable);
        } else if (json.status === "error") {
        }
      })
      .catch((error) => {});
  };

  /**
   * Se da un seteo inicial a los diferentes hooks de estado.
   * Se realiza una peticion fetch:
   * si la peticion es exitosa se setean las @constant saldo
   * @constant saldoTotal
   * @constant montoMaximoRetiroDirecto
   * @constant agenciaRetiros
   * @constant solicitudEnCurso
   * @constant solicitud
   * en caso de error se llama a la @function mensajeErrorWS
   */
  const iniciarlizarRetiro = () => {
    if (isOrgAndNotLoggedIn) return;

    setinicioOK(true);
    seterror(false);
    settoken("");
    setnroQR("");
    setdisabled(false);
    setmontoMaximoRetiroDirecto(0);
    setmontoSolicitud("");
    setsaldo(0);
    setsaldoTotal(0);
    setsolicitudGenerada(false);
    setvigenciaSolicitud("");
    setsolicitudEnCurso(false);
    setvisualizarQR(false);
    setsolicitud({});
    setagenciaRetiros({});

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_INIT_RETIRO;

    let closeSession = false;

    setcargando("EJECUTANDO");

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        setcargando("FIN");
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setcargando("FIN");

          let aSolicitud = json.retiro;
          if (aSolicitud == null) {
            aSolicitud = { monto: "" };
          }
          setsaldo(json.saldoRetirable);
          setsaldoTotal(json.saldo);
          setmontoMaximoRetiroDirecto(json.minImporte);
          setagenciaRetiros(json.agAmiga);
          setsolicitudEnCurso(json.solicitudEnCurso);
          setsolicitud(aSolicitud);
          // setUltimoCbu(json.ultimoCbu);
          if (checkOrg(["53", "23"])) {
            getCBU();
          }
        } else {
          if (json.status === "error") {
            if (closeSession) {
              mensajeErrorWS("Retiros", json.errores, closeSession);
            } else {
              setinicioOK(false);
              mensajeErrorWS("Retiros", json.errores);
            }
          } else {
            setinicioOK(false);
          }
        }
      })
      .catch((error) => {
        setcargando("FIN");
        setinicioOK(false);
        //mensajeErrorGeneral();
      });
  };

  const getCBU = () => {
    let urlCBU =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GET_FIRST_CBU;

    fetch(urlCBU, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => respPromise.json())
      .then((json) => {
        setUltimoCbu(json.cbu ?? "");
      })
      .catch((error) => {
        setUltimoCbu("");
      });
  };

  /**
   * Se realiza una peticion fetch, en caso que la llamada sea correcta deberá traer un tokenQR y una tokenCode
   * esos se setearan en los estados para generar el codigo QR
   */
  const generarQR = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GET_QR_IDENTIF_CLI;

    let closeSession = false;

    setcargandoQR(true);
    settoken("");
    setnroQR("");

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        setcargandoQR(false);
        if (json.status === "ok") {
          setdisabled(false);

          seterror(false);
          settoken(json.tokenQR);
          setnroQR(json.tokenCode);
        } else if (json.status === "error") {
          setdisabled(false);

          seterror(true);
          if (closeSession) {
            mensajeErrorWS("Generación QR", json.errores, closeSession);
          } else {
            //mensajeErrorWS('Generación QR',json.errores);
          }
        }
      })
      .catch((error) => {
        setdisabled(false);
        setcargandoQR(false);
        seterror(true);

        refreshSaldo();
      });
  };

  /**
   * Inicialmente se verifica que el usuario haya colocado un importe a retirar, en caso que no lo haya hecho le saldrá una notificacion
   * donde le dira que debe ingresar un importe. en caso que el importe se haya ingresado se realizará una peticion fetch (POST) donde se
   * pasarán los siguientes parametros: codigoOrganizacion, importe, agencia y cancelar. en el caso que la peticion sea correcta se ejecutara
   * la funcion @iniciarlizarRetiro
   */
  const grabarSolicitudDeRetiro = () => {
    if (
      $("#idMontoSolicitud").val().trim() === "" ||
      $("#idMontoSolicitud").val() === "0"
    ) {
      $.confirm({
        title: '<b className="op-text">Solicitud de retiro</b>',
        backgroundDismiss: true,
        columnClass: "medium",
        animation: "zoom",
        closeIcon: true,
        closeAnimation: "scale",
        content: `<h5 className="text-center mt-4 mb-2">
									Debe ingresar el importe.
								  </h5>`,
        buttons: {
          Aceptar: function () {
            setgrabandoSolicitud(false);
            return;
          },
        },
      });
    } else {
      let closeSession = false;

      if (extractionType === "transfer") {
        let url =
          process.env.REACT_APP_URL_BASE_BACKEND +
          process.env.REACT_APP_WS_GRABAR_RETIRO_TRANSFERENCIA;

        let parametrosTransferencia = {
          agencia: agenciaRetiros != null ? agenciaRetiros.codigo : null,
          cancelar: false,
          cbu: ultimoCbu,
          cuil: window.getCuil(),
          importe: montoConDecimales,
        };

        fetchSolicitudRetiroTransferencia(
          url,
          parametrosTransferencia,
          closeSession
        );
      } else {
        let url =
          process.env.REACT_APP_URL_BASE_BACKEND +
          process.env.REACT_APP_WS_GRABAR_RETIRO;

        let parametros = {
          codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
          importe: montoConDecimales,
          agencia: agenciaRetiros != null ? agenciaRetiros.codigo : null,
          cancelar: false,
        };

        fetchSolicitudRetiro(url, parametros, closeSession);
      }
    }
  };

  const handleCancelTransfer = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GRABAR_RETIRO_TRANSFERENCIA;
    let closeSession = false;

    let parametrosTransferencia = {
      agencia: agenciaRetiros != null ? agenciaRetiros.codigo : null,
      cancelar: true,
      cbu: ultimoCbu,
      cuil: window.getCuil(),
      importe: solicitud.importe,
    };

    setisLoadingSolicitud(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametrosTransferencia),
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          iniciarlizarRetiro();
          refreshSaldo();
        } else if (json.status === "error") {
          if (closeSession) {
            mensajeErrorWS(
              "Cancelo solicitud de retiro",
              json.errores,
              closeSession
            );
          } else {
            seterrores(json.errores);
            setcambioExitoso(false);
            mensajeErrorWS("Canceló solicitud de retiro", json.errores);
          }
        }
      })
      .catch((error) => {
        mensajeErrorGeneral();
      })
      .finally(() => {
        setisLoadingSolicitud(false);
      });
  };

  const fetchSolicitudRetiro = (url, parametros, closeSession) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          iniciarlizarRetiro();
          setgrabandoSolicitud(false);

          history.push("/temp");
          history.goBack();
        } else if (json.status === "error") {
          setgrabandoSolicitud(false);
          if (closeSession) {
            mensajeErrorWS("Solicitud de retiro", json.errores, closeSession);
          } else {
            mensajeErrorWS("Solicitud de retiro", json.errores);
          }
        }
      })
      .catch((error) => {
        setgrabandoSolicitud(false);
        mensajeErrorGeneral();
      });
  };
  const fetchSolicitudRetiroTransferencia = (url, parametros, closeSession) => {
    setisLoadingSolicitud(true);
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          iniciarlizarRetiro();
          setgrabandoSolicitud(false);

          history.push("/temp");
          history.goBack();
        } else if (json.status === "error") {
          setgrabandoSolicitud(false);
          if (closeSession) {
            mensajeErrorWS("Solicitud de retiro", json.errores, closeSession);
          } else {
            mensajeErrorWS("Solicitud de retiro", json.errores);
          }
        }
      })
      .catch((error) => {
        setgrabandoSolicitud(false);
        mensajeErrorGeneral();
      })
      .finally(() => {
        setisLoadingSolicitud(false);
      });
  };

  /**
   * Es funcion se encarga de generar una imagen en formato png del QR y la descarga
   */
  const downloadImg = () => {
    let qr = document.getElementById("download");

    htmlToImage.toPng(qr).then(function (dataUrl) {
      downloadjs(dataUrl, "QR-identificacion.png");
    });
  };

  const checkDisableCancelBtn = () => {
    if (checkOrg(["53", "23"])) {
      if (
        solicitud.modoPedidoRetiro === "TRANSFERENCIA" &&
        solicitud.estado !== "En progreso"
      )
        return true;
      return false;
    }

    return false;
  };

  return (
    <DivGeneral className="pageFlow">
      <Animated
        animationIn="fadeIn"
        hidden={estadoPlataforma.estado !== "PLATAFORMA"}
      >
        {solicitudEnCurso &&
          inicioOK &&
          solicitud.modoPedidoRetiro !== "TRANSFERENCIA" && (
            <SolicitudEnCurso
              logoRetiro={logoRetiro}
              solicitud={solicitud}
              direccionLoteria={
                instalaciones[process.env.REACT_APP_COD_ORGANIZACION].direccion
              }
              clickCancelarSolicitud={clickCancelarSolicitud}
              clickGenerarQRSolRetiro={clickGenerarQRSolRetiro}
            />
          )}

        {isLoadingSolicitud && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: "100px 30px",
            }}
            className="container"
          >
            <EfectoCargando />
          </div>
        )}

        {/* Solicitud de retiro por transferencia */}
        {solicitudEnCurso &&
          !isLoadingSolicitud &&
          inicioOK &&
          solicitud.modoPedidoRetiro === "TRANSFERENCIA" && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "3rem",
              }}
            >
              <div>
                <span className="m-bold mr-1">Fecha de solicitud:</span>
                <label>{solicitud.fechaPedido.substring(0, 10) ?? "-"}</label>
              </div>
              <Animated
                animationIn="zoomIn"
                animationInDelay={800}
                animationInDuration={500}
              >
                <div>
                  <span
                    style={{ color: color }}
                    className="iconify"
                    data-icon="cil:check"
                    data-inline="false"
                    data-width="60px"
                  ></span>
                </div>
              </Animated>
              <div
                className="m-bold"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  lineHeight: "1.2rem",
                }}
              >
                <h5 className="mb-0">Hemos recibido</h5>
                <div className="d-flex">
                  <h5 className="mb-0 mr-1">{`tu solicitud de`}</h5>
                  <h5
                    className="m-bold"
                    style={{ margin: 0, color: stylesByOrg.success }}
                  >
                    <NumberFormat
                      value={solicitud.importe}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$ "}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      isNumericString={true}
                    />
                  </h5>
                </div>
                <h5 className="mb-0">y esta en proceso.</h5>
              </div>
              <button
                type="button"
                className={
                  ["20", "50"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                    ? "btn qr-btn-cancel-prov-" +
                      process.env.REACT_APP_COD_ORGANIZACION
                    : "btn btn-danger"
                }
                style={{ marginTop: "1rem" }}
                data-toggle="modal"
                onClick={clickCancelarSolicitud}
                disabled={checkDisableCancelBtn()}
              >
                Cancelar
              </button>
            </div>
          )}

        {/* Solicitud retiro por punto de venta */}
        {solicitudEnCurso === false && !isLoadingSolicitud && inicioOK && (
          <PaginaSolicitudRetiro
            logoRetiro={logoRetiro}
            cargando={cargando}
            saldo={saldo}
            grabandoSolicitud={grabandoSolicitud}
            montoConDecimales={montoConDecimales}
            agenciaRetiros={agenciaRetiros}
            handleInputBlur={handleInputBlur}
            setmontoConDecimales={setmontoConDecimales}
            clickGenerarSolicitud={clickGenerarSolicitud}
            logueado={estadoPlataforma.logueado}
            ultimoCbu={ultimoCbu}
            setUltimoCbu={setUltimoCbu}
            extractionType={extractionType}
            setExtractionType={setExtractionType}
          />
        )}

        {inicioOK === false && (
          <PaginaSinConexion iniciarlizarRetiro={iniciarlizarRetiro} />
        )}
        <br />
        <br />
        <br />
        <br />

        <QrModal
          error={error}
          token={token}
          nroQR={nroQR}
          solicitud={solicitud}
          cargandoQR={cargandoQR}
          clickGenerarQR={clickGenerarQR}
          downloadImg={downloadImg}
        />
      </Animated>
    </DivGeneral>
  );
};

export default withRouter(RetiroDinero);
