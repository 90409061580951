import React from "react";
const YellowBall = ({ size }) => {
  return (
    <svg
      width={size ?? "70"}
      height={size ?? "70"}
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35" cy="35" r="35" fill="url(#paint0_radial_164_2425)" />
      <defs>
        <radialGradient
          id="paint0_radial_164_2425"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35 35) rotate(90) scale(35)"
        >
          <stop offset="0.045" stopColor="#FFFDC1" />
          <stop offset="0.44" stopColor="#F2EC59" />
          <stop offset="1" stopColor="#E8DF05" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default YellowBall;
