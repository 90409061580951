import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import ButtonSalta from "./ButtonSalta";
import $ from "jquery";
import { oneLineStyles } from "../../utils/generalUtils";

export const NavbarSaltaUserInfo = ({
  currentPage,
  estadoPlataforma,
  volverAPlataforma,
  colorFont,
  size,
}) => {
  const getLeftSection = () => {
    if (!estadoPlataforma.logueado)
      return (
        <DivLeftSection
          style={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <SpanFont color={colorFont} className="m-bold">
            ¡Hola!
          </SpanFont>
        </DivLeftSection>
      );

    return (
      <Link
        onClick={() => volverAPlataforma()}
        to="/misDatosPersonales"
        style={{ fontSize: "1.2rem", textAlign: "left", color: colorFont }}
        className="m-bold"
      >
        {estadoPlataforma.nombre ? (
          <>
            ¡Hola{" "}
            {estadoPlataforma.nombre.indexOf(" ") >= 0
              ? estadoPlataforma.nombre.substr(
                  0,
                  estadoPlataforma.nombre.indexOf(" ")
                )
              : estadoPlataforma.nombre}
            !
          </>
        ) : (
          <>¡Hola {estadoPlataforma.email?.split("@")[0] ?? ""}!</>
        )}
      </Link>
    );
  };

  return (
    <div
      className={`${
        !estadoPlataforma.logueado
          ? "col-3 col-md-2 col-lg-3"
          : "col-5 col-sm-6 col-md-3"
      }`}
      style={{
        padding: 0,
        overflow: "hidden",
        flex: size <= 820 || currentPage === "usuario" ? 1 : "",
        maxWidth: size <= 820 || currentPage === "usuario" ? "unset" : "",
      }}
    >
      <div style={{ ...oneLineStyles, width: "fit-content", color: colorFont }}>
        {currentPage !== "usuario" && getLeftSection()}
      </div>
      {currentPage === "usuario" && (
        <div
          style={{
            ...oneLineStyles,
            width: "fit-content",
            color: colorFont,
            fontSize: "1.2rem",
          }}
          className="m-bold"
        >
          {estadoPlataforma.nombre ? (
            <>{estadoPlataforma.nombre}</>
          ) : (
            <>{estadoPlataforma.email?.split("@")[0] ?? ""}</>
          )}
        </div>
      )}
    </div>
  );
};

const DivLeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SpanFont = styled.span`
  color: ${(props) => props.color};
  font-size: 1.2rem;
  text-align: left;
`;

export const NavbarSaltaWalletInfo = ({
  currentPage,
  estadoPlataforma,
  size,
  clickRefrescarSaldo,
  mostrarSaldo,
  estadoOjo,
  handleClickOjoSaldo,
}) => {
  if (!(currentPage !== "usuario" && currentPage !== "registrarse"))
    return <></>;

  return (
    <Col4Billetera
      top={size <= 820 ? "10vh" : "0"}
      className={size <= 820 ? "col-12 d-flex justify-content-center" : "col-4"}
      position={size <= 820 ? "absolute" : "relative"}
    >
      <Animated animationIn="flipInX">
        <h5 onClick={clickRefrescarSaldo}>
          <ContainerSaldo aplicarGradiente={true} className="container">
            <div className="row">
              <div className="col-2" style={{ padding: "6px" }}>
                <span
                  className="iconify"
                  data-icon="ion:wallet-outline"
                  data-inline="false"
                  data-width="50px"
                ></span>
              </div>
              <div className="col-2" style={{ padding: "6px" }}>
                <span
                  className="m-bold"
                  style={{ textShadow: "3px 2px 4px grey" }}
                >
                  Saldo<br></br>actual
                </span>
              </div>
              <MontoSaldo color={"#01C160"} className="col-6">
                <div>
                  {!mostrarSaldo ? (
                    <NumberFormat
                      value={estadoPlataforma.saldo || "0"}
                      displayType={"text"}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      prefix={"$ "}
                      decimalScale={2}
                      fixedDecimalScale={false}
                      isNumericString={true}
                      style={{
                        textShadow: "1px 2px 1px grey",
                        fontSize: "1.1em",
                      }}
                    />
                  ) : (
                    <span
                      style={{
                        textShadow: "1px 2px 1px grey",
                        fontSize: "100%",
                      }}
                    >
                      $ ***********
                    </span>
                  )}
                </div>
              </MontoSaldo>
              {
                <div
                  className="col-2"
                  style={{
                    position: "fixed",
                    right: "0.5%",
                    top: "1em",
                  }}
                  onClick={handleClickOjoSaldo}
                >
                  {estadoOjo && (
                    <Animated
                      animationIn="fadeIn"
                      animationInDuration={200}
                      animationOutDuration={200}
                      animateOnMount={false}
                      isVisible={estadoOjo}
                    >
                      <span
                        id="eye"
                        data-width="20px"
                        className="iconify op-text"
                        data-icon={"solar:eye-outline"}
                        data-inline="false"
                      />
                    </Animated>
                  )}
                  {!estadoOjo && (
                    <Animated
                      animationIn="fadeIn"
                      animationInDuration={200}
                      animationOutDuration={200}
                      animateOnMount={false}
                      isVisible={!estadoOjo}
                    >
                      <span
                        id="eye"
                        data-width="20px"
                        className="iconify op-text"
                        data-icon={"solar:eye-closed-outline"}
                        data-inline="false"
                      />
                    </Animated>
                  )}
                </div>
              }
            </div>
          </ContainerSaldo>
        </h5>
      </Animated>
    </Col4Billetera>
  );
};

const Col4Billetera = styled.div`
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  z-index: 3;
  left: 0%;
`;

const ContainerSaldo = styled.div`
  background-color: #f1f1f1;
  border-radius: 1em;
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px 8px 7px 2px #2826268a" : ""};
  min-width: 20rem;
  max-width: 100%;
`;

const MontoSaldo = styled.div`
  color: ${(props) => props.color};
  text-align: center;
  padding-top: 1em;
  font-family: "Mont-Bold";
  white-space: nowrap;
`;

const smallBtn = { fontSize: ".85rem", padding: "8px 15px" };
const xsBtn = { fontSize: ".7rem", padding: "8px 10px" };

export const NavbarSaltaRightActions = ({
  history,
  estadoPlataforma,
  notificacionesJuego,
  colorFont,
  logueado,
  mensajesSinLeer,
  size,
  manualJuego,
  traerDatosJuegoResponsable,
  checkNotRequireUser,
  traerMensGrales,
  setPositionBtnLogin,
  currentPage,
}) => {
  const btnRef = useRef(null);

  useEffect(() => {
    if (btnRef.current) {
      const rect = btnRef.current.getBoundingClientRect();
      setPositionBtnLogin({ top: rect.bottom, left: rect.right });
    }
  }, [setPositionBtnLogin]);

  return (
    <DivContainerRightActions
      size={size}
      style={{ flex: size <= 820 || currentPage === "usuario" ? "unset" : "" }}
    >
      {!estadoPlataforma.logueado && (
        <div
          style={{
            display: "flex",
            gap: size <= 400 ? "6px" : "10px",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: size <= 500 ? "nowrap" : "wrap",
            position: "relative",
          }}
        >
          <ButtonSalta
            ref={btnRef}
            variant="primary"
            style={size <= 400 ? xsBtn : smallBtn}
            onClick={() => $("#loginModal").modal("show")}
          >
            Acceso
          </ButtonSalta>
          <ButtonSalta
            variant="light"
            style={size <= 400 ? xsBtn : smallBtn}
            onClick={() => history.push("/registrarse")}
          >
            Registro
          </ButtonSalta>
        </div>
      )}

      <div>
        {checkNotRequireUser() && (
          <div>
            {manualJuego !== undefined &&
            estadoPlataforma.estado !== "PLATAFORMA" ? (
              manualJuego
            ) : (
              <JuegoResponsableDiv
                color={colorFont}
                data-toggle="modal"
                data-target={"#juegoResponsableModal"}
                onClick={traerDatosJuegoResponsable}
                className="my-icon"
              >
                <ImagenJuegoresp
                  src={
                    require(`../../img/icons/juegoresp${"blanco"}.png`).default
                  }
                  alt="Juego_Responsable_Log"
                />
              </JuegoResponsableDiv>
            )}
          </div>
        )}
      </div>

      <div>
        {notificacionesJuego !== undefined &&
        estadoPlataforma.estado !== "PLATAFORMA" ? (
          notificacionesJuego
        ) : (
          <div
            data-toggle="modal"
            data-target={!logueado ? "" : "#notiModal"}
            onClick={traerMensGrales}
            style={{
              width: "fit-content",
              cursor: !logueado ? "default" : "pointer",
              pointerEvents: !logueado ? "none" : "all",
              opacity: !logueado ? 0.5 : 1,
              position: "relative",
            }}
          >
            <div className="my-icon">
              <span
                color={colorFont}
                className="iconify"
                data-icon="bytesize:bell"
                data-inline="false"
                data-width="28px"
              ></span>
            </div>

            {mensajesSinLeer !== 0 && (
              <SpanNotificaciones
                size={size}
                className="badge badge-danger noti-badge "
                style={{ top: "15px", left: "-6px" }}
              >
                {logueado ? mensajesSinLeer : ""}
              </SpanNotificaciones>
            )}
          </div>
        )}
      </div>
    </DivContainerRightActions>
  );
};

const JuegoResponsableDiv = styled.div`
  cursor: pointer;
  color: ${(props) => props.color};
  width: 28px;
  height: 28px;
`;

const ImagenJuegoresp = styled.img`
  width: 100%;
  height: 100%;
`;

const SpanNotificaciones = styled.span`
  left: ${(props) => (props.size <= 500 ? "2vw" : "0.7vw")};
  top: 0px;
`;

const DivContainerRightActions = styled.div`
  display: flex;
  gap: ${(props) => (props.size <= 400 ? "20px" : "25px")};
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  margin: 0 20px;
  min-width: 100px;
`;
