export const estilosProv = {
  41: {
    fondo: "#0046ac",
    fontColor: "white",
    fondoLlamador: "#0046ac",
    gradiente1Llamador: "#edf1f5",
    gradiente2Llamador: "#edf1f5",
    secundaryColor: "#0046ac",
    numeroWhatsapp: "2966-631277",
    mail: "contacto@lotline.com.ar",
    dias: "Lunes a Viernes: 10:00hs a 20:00hs.",
    juegoResponsable: "",
  },
  11: {
    fondo: "#0046ac",
    fontColor: "white",
    fondoLlamador: "#0046ac",
    gradiente1Llamador: "#edf1f5",
    gradiente2Llamador: "#edf1f5",
    secundaryColor: "#0046ac",
    numeroWhatsapp: "2966-631277",
    mail: "contacto@lotline.com.ar",
    dias: "Lunes a Viernes: 10:00hs a 20:00hs.",
    juegoResponsable: "",
  },
  42: {
    fondo: "#ffcc00",
    fontColor: "black",
    fondoLlamador: "#ffcc00",
    gradiente1Llamador: "#ffeea1",
    gradiente2Llamador: "#2122411f",
    secundaryColor: "#ffcc00",
    telefono: "299 4576784",
    mail: "contacto@laneuquinaonline.bet.ar",
    dias: "Lunes a Sábados: 08:00hs a 22:00hs.",
    juegoResponsable: "",
  },
  12: {
    fondo: "#ffcc00",
    fontColor: "black",
    fondoLlamador: "#ffcc00",
    gradiente1Llamador: "#ffeea1",
    gradiente2Llamador: "#2122411f",
    secundaryColor: "#ffcc00",
    telefono: "299 4576784",
    mail: "contacto@laneuquinaonline.bet.ar",
    dias: "Lunes a Sábados: 08:00hs a 22:00hs.",
    juegoResponsable: "",
  },
  53: {
    fondo: "#5601b7",
    fontColor: "white",
    fondoLlamador: "#5601b7",
    gradiente1Llamador: "#ffe3cf",
    gradiente2Llamador: "#ffe3cf",
    secundaryColor: "#5601b7",
    numeroWhatsapp: "3876029474",
    mail: "contacto@loteriadesaltaonline.com.ar",
    dias: "Lunes a Viernes: 08:00hs a 17:00hs.",
    juegoResponsable: "",
    success: "#00C975"
  },
  23: {
    fondo: "#5601b7",
    fontColor: "white",
    fondoLlamador: "#5601b7",
    gradiente1Llamador: "#ffe3cf",
    gradiente2Llamador: "#ffe3cf",
    secundaryColor: "#5601b7",
    numeroWhatsapp: "3876029474",
    mail: "contacto@loteriadesaltaonline.com.ar",
    dias: "Lunes a Viernes: 08:00hs a 17:00hs.",
    juegoResponsable: "",
    success: "#00C975"
  },
  43: {
    fondo: "#01C160",
    fontColor: "white",
    fondoLlamador: "#19193a",
    gradiente1Llamador: "#aeaeef",
    gradiente2Llamador: "#5e5ef7",
    secundaryColor: "#01C160",
    numeroWhatsapp: "2920-487001",
    mail: "contacto@larionegrinaonline.com.ar",
    dias: "Lunes a Viernes: 10:00hs a 20:00hs.",
    juegoResponsable: "",
  },
  13: {
    fondo: "#01C160",
    fontColor: "white",
    fondoLlamador: "#19193a",
    gradiente1Llamador: "#aeaeef",
    gradiente2Llamador: "#5e5ef7",
    secundaryColor: "#01C160",
    numeroWhatsapp: "2920-487001",
    mail: "contacto@larionegrinaonline.com.ar",
    dias: "Lunes a Viernes: 10:00hs a 20:00hs.",
    juegoResponsable: "",
  },
  20: {
    fondo: "#c20d19",
    fontColor: "white",
    fondoLlamador: "#feee00",
    gradiente1Llamador: "#feee0073",
    gradiente2Llamador: "#02020257",
    secundaryColor: "#ee7511",
    numeroWhatsapp: "(379) 442-9588 / (379) 447-6229 ",
    mail: "contacto@lacorrentinaonline.com.ar",
    dias: "Lunes a Sábados: 08:00 a 13:00hs y de 16:00 a 20:00hs.",
    juegoResponsable: "",
  },
  50: {
    fondo: "#c20d19",
    fontColor: "white",
    fondoLlamador: "#feee00",
    gradiente1Llamador: "#feee0073",
    gradiente2Llamador: "#02020257",
    secundaryColor: "#ee7511",
    numeroWhatsapp: "(379) 442-9588 / (379) 447-6229 ",
    mail: "contacto@lacorrentinaonline.com.ar",
    dias: "Lunes a Sábados: 08:00 a 13:00hs y de 16:00 a 20:00hs.",
    juegoResponsable: "",
  },
  18: {
    fondo: "#3B9FA7",
    fontColor: "white",
    fondoLlamador: "transparent",
    gradiente1Llamador: "transparent",
    gradiente2Llamador: "transparent",
    secundaryColor: "#ee7511",
    numeroWhatsapp: "54 9 3885 09-5085",
    telefono: "0800-444-1550",
    mail: "contacto@lajujeñitaonline.bet.ar",
    dias: "Lunes a Viernes: 10:00hs a 20:00hs.",
    diasFinde: "Sabado de 9 a 21hs y Domimgo de 9:30 a 15hs.",
    juegoResponsable: "https://inprojuy.gob.ar/#/juegoResponsable",
  },
  48: {
    fondo: "#3B9FA7",
    fontColor: "white",
    fondoLlamador: "transparent",
    gradiente1Llamador: "transparent",
    gradiente2Llamador: "transparent",
    secundaryColor: "#ee7511",
    numeroWhatsapp: "54 9 3885 09-5085",
    telefono: "0800-444-1550",
    mail: "contacto@lajujeñitaonline.bet.ar",
    dias: "Lunes a Viernes: 10:00hs a 20:00hs.",
    diasFinde: "Sabado de 9 a 21hs y Domimgo de 9:30 a 15hs.",
    juegoResponsable: "https://inprojuy.gob.ar/#/juegoResponsable",
  },
};
