/* eslint-disable jsx-a11y/alt-text */

import React from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";
import $ from "jquery";
import "jquery-confirm";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";
import Recaptcha from "react-recaptcha";

import OlvideClave from "./olvideClave";
import { useMediaQuery } from "react-responsive";

import logoLeyenda from "../src/img/LOGO_LEYENDA.png";
import logoJugaXJugar from "../src/img/LOGOJUGAXJUGAR.png";
import { checkOrgAndNotLoggedIn } from "./utils/generalUtils";

// eslint-disable-next-line no-unused-vars
const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  return isDesktop ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 });
  return isTablet ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ minWidth: 320, maxWidth: 767 });
  return isMobile ? children : null;
};
// eslint-disable-next-line no-unused-vars
const MobileHorizontal = ({ children }) => {
  const isMobileHorizontal = useMediaQuery({ maxWidth: 767 });
  return isMobileHorizontal ? children : null;
};
// eslint-disable-next-line no-unused-vars
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickBorrarCampos = this.handleClickBorrarCampos.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.loguearse = this.loguearse.bind(this);
    this.irAlHome = this.irAlHome.bind(this);
    this.olvideClave = this.olvideClave.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    this.onExpiroCaptcha = this.onExpiroCaptcha.bind(this);
    this.irARegistracion = this.irARegistracion.bind(this);
    this.handlePassView = this.handlePassView.bind(this);
    this.cargarImgDinamica = this.cargarImgDinamica.bind(this);
    this.generoNuevaClave = this.generoNuevaClave.bind(this);
    this.uncheck = this.uncheck.bind(this);
    this.uncheckOnError = this.uncheckOnError.bind(this);

    this.state = {
      value: "",
      envioGeneracion: false,
      nroDocu: localStorage.usrname,
      clave: localStorage.pass,
      checked: false,
      pass: false,
      cargando: "INICIO",
      nombre: "",
      apellido: "",
      userToken: "",
      saldo: 0,
      requiereCambio: undefined,
      errores: [],
      logueado: false,
      captchaVerificado: false,
      errors: {
        nroDocu: "",
        clave: "",
        captcha: "",
      },
    };
  }

  uncheck() {
    if ($("#remember_me").is(":checked")) {
    } else {
      this.setState({ nroDocu: "", clave: "" });
      $("#pasLabel").removeClass("hide");
    }

    this.setState({ checked: !this.state.checked });
  }

  uncheckOnError() {
    this.setState({ clave: "", checked: false });
    $("#pasLabel").removeClass("hide");
  }

  cargarImgDinamica(nombre1, nombre2, nombre3, nombre4, nombre5, nombre6) {
    import(
      `./img/${nombre1}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ logoSplash: image.default });
    });

    import(
      `./img/${nombre2}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ loginImg: image.default });
    });

    import(
      `./img/${nombre3}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ logoOrg: image.default });
    });
    import(
      `./img/${nombre4}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ logoApp: image.default });
    });
    import(
      `./img/${nombre6}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      this.setState({ loginMImg: image.default });
    });
  }

  generoNuevaClave(enviada) {
    this.setState({ envioGeneracion: enviada });
  }

  handlePassView() {
    let x = $("#clave");
    if (x.hasClass("text-pass")) {
      x.removeClass("text-pass");
    } else {
      /* LOGO_SPLASH_13 */
      x.addClass("text-pass");
    }

    this.setState({ pass: !this.state.pass });
  }

  irARegistracion() {
    this.props.history.push("/registrarse");
  }

  onExpiroCaptcha() {
    this.setState({ captchaVerificado: false });
  }

  verifyCallback() {
    this.setState({ errors: { captcha: "" } });
    this.setState({ captchaVerificado: true });
  }

  onLoadRecaptcha() {
    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
      this.setState({ captchaVerificado: false });
    }
  }

  olvideClave() {
    this.props.history.push("/olvideClave");
  }

  componentDidMount() {
    let isOrgAndNotLoggedIn = checkOrgAndNotLoggedIn(
      ["53", "23"],
      this.props.estadoPlataforma.logueado
    );
    if (isOrgAndNotLoggedIn) {
      this.props.history.push("/home");
      return;
    }
    // Hace que lleguen los parámtros del ROUTE
    //const { handle } = this.props.match.params;

    if (
      sessionStorage.getItem("Usuario") !== null ||
      sessionStorage.getItem("login") !== null
    ) {
      let usuario = JSON.parse(sessionStorage.getItem("Usuario"));
      let login = JSON.parse(sessionStorage.getItem("login"));

      this.setState(usuario);
      this.props.login(
        login.nombre,
        login.apellido,
        login.key,
        login.autoExclusionHasta,
        login.acceptedTyC,
        login.nps,
        login.requiereCambioContraseña
      );

      this.props.setSaldo(usuario.saldo);

      this.props.setCambio(login.requiereCambioContraseña);
      this.props.history.push("/home");
    }

    this.cargarImgDinamica(
      "LOGO_SPLASH",
      "LOGIN_DESKTOP",
      "LOGO_ORG",
      "LOGO_APP",
      "HORIZONTAL_ORG",
      "LOGIN_MOBILE"
    );

    this.setState({
      nroDocu: localStorage.usrname,
      clave: localStorage.pass,
      checked: localStorage.chkbx,
    });

    if (localStorage.chkbx === "on") {
      $("#pasLabel").addClass("hide");
    }

    this.props.logCurrentPage("login");

    if (this.props.estadoPlataforma.logueado) this.props.history.push("/home");

    //$("#nroDocu").focus();

    this.props.actuVersionSiCorresponde(this.props);

    if (this.recaptchaInstance) {
      this.recaptchaInstance.reset();
      this.setState({ captchaVerificado: false });
    }

    $("#show_hide_password a").on("click", function (event) {
      event.preventDefault();
      if ($("#show_hide_password input").attr("type") == "text") {
        $("#show_hide_password input").attr("type", "password");
        $("#show_hide_password i").addClass("fa-eye-slash");
        $("#show_hide_password i").removeClass("fa-eye");
      } else if ($("#show_hide_password input").attr("type") == "password") {
        $("#show_hide_password input").attr("type", "text");
        $("#show_hide_password i").removeClass("fa-eye-slash");
        $("#show_hide_password i").addClass("fa-eye");
      }
    });
  }

  handleClickBorrarCampos = (event) => {
    $("#nroDocu").val("");
    $("#clave").val("");

    let limpiarErrors = {
      nroDocu: "",
      clave: "",
      captcha: "",
    };

    this.setState({ errors: limpiarErrors });
  };

  irAlHome() {
    this.props.history.push("/home");
  }

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "nroDocu":
        errors.nroDocu =
          value.length === 0 ? "Ingresar nro. de documento o correo " : "";
        break;
      case "clave":
        if (value === "") {
          $("#pasLabel").removeClass("hide");
        } else {
          $("#pasLabel").addClass("hide");
        }

        errors.clave =
          value.length < 8
            ? "La clave debe contener al menos 8 caracteres"
            : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value }, () => {});
  };

  validateForm() {
    let valid = true;

    let errors = this.state.errors;

    if ($("#nroDocu").val() === "") {
      errors.nroDocu = "Ingresar nro. de documento o correo";
      valid = false;
    }

    if ($("#clave").val() === "") {
      errors.clave = "Ingresar clave";
      valid = false;
    }

    if (errors.nroDocu.length > 0) $("#nroDocu").focus();
    else if (errors.clave.length > 0) $("#clave").focus();

    if (
      this.state.captchaVerificado === false &&
      process.env.REACT_APP_ACTIVAR_CAPTCHA_LOGIN === "si"
    ) {
      errors.captcha = "Debes indicar que no eres un robot";
      if (valid) {
        valid = false;
        $("#IngresoCaptcha").focus();
        //qthis.props.mensajeErrorWS('Indique que no es un Robot',[{codigo:"",error:"Debe indicar que no es un robot para ingresar al sistema"}]);
      }
    }

    return valid;
  }

  loguearse() {
    this.setState({ cargando: "EJECUTANDO" });
    var formBody = [];

    var userName = encodeURIComponent("username");
    var valueUserName = encodeURIComponent($("#nroDocu").val().trim());
    formBody.push(userName + "=" + valueUserName);

    var clave = encodeURIComponent("password");
    var valueClave = encodeURIComponent($("#clave").val().trim());
    formBody.push(clave + "=" + valueClave);

    var codigoOrganizacion = encodeURIComponent("codigoOrganizacion");
    var valueCodigoOrganizacion = encodeURIComponent(
      process.env.REACT_APP_COD_ORGANIZACION_FETCH
    );
    formBody.push(codigoOrganizacion + "=" + valueCodigoOrganizacion);

    formBody = formBody.join("&");

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND + process.env.REACT_APP_WS_LOGIN;

    let statusCode = "";

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: formBody,
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          this.setState({
            errores: [],
            logueado: true,
            nombre: json.nombre,
            apellido: json.apellido,
            saldo: json.saldo,
            userToken: json.key,
          });
          sessionStorage.setItem(
            "Usuario",
            JSON.stringify({
              errores: [],
              logueado: true,
              nombre: json.nombre,
              apellido: json.apellido,
              saldo: json.saldo,
              userToken: json.key,
            })
          );
          this.setState({ cargando: "FIN" });

          this.props.login(
            json.nombre,
            json.apellido,
            json.key,
            json.autoExclusionHasta,
            json.acceptedTyC,
            json.nps,
            json.requiereCambioContraseña
          );
          sessionStorage.setItem(
            "login",
            JSON.stringify({
              nombre: json.nombre,
              apellido: json.apellido,
              key: json.key,
              autoExclusionHasta: json.autoExclusionHasta,
              acceptedTyC: json.acceptedTyC,
              nps: json.nps,
              requiereCambioContraseña: json.requiereCambioContraseña,
            })
          );
          this.props.setSaldo(json.saldo);

          this.props.setCambio(json.requiereCambioContraseña);
          // this.props.setCambio(true);

          if (localStorage.chkbx === "on") {
            // save username and password
            localStorage.usrname = this.state.nroDocu;
            localStorage.pass = this.state.clave;
          }

          if (
            this.props.notificacionMensajes != null &&
            this.props.notificacionMensajes.ejecutar != null
          )
            this.props.notificacionMensajes.ejecutar();

          if (this.props.estadoPlataforma.operRequirioLogin === "LOGIN")
            this.props.history.push("/home");
          else if (
            this.props.estadoPlataforma.operRequirioLogin
              .toUpperCase()
              .startsWith("HTTP")
          ) {
            this.props.history.push("/home");
            var str = "";
            if (process.env.REACT_APP_CODIGO_JUEGO === "0")
              str =
                process.env.REACT_APP_URL_BASE_FRONTEND +
                process.env.REACT_APP_URL_JUEGO_TOMBOLA;
            else if (process.env.REACT_APP_CODIGO_JUEGO === "1")
              str =
                process.env.REACT_APP_URL_BASE_FRONTEND +
                process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS;
            if (str.slice(-1) === "/") str = str.slice(0, -1);

            this.props.arrancarJuego(
              this.props.estadoPlataforma.operRequirioLogin
            );
          } else {
            this.props.history.push("/home");
          }
        } else if (json.status === "error") {
          this.uncheckOnError();

          this.setState({ cargando: "FIN" });

          this.setState({
            errores: json.errores,
            logueado: false,
            nombre: "",
            apellido: "",
            saldo: 0,
            userToken: "",
          });
          this.props.mensajeErrorWS("Ingreso al sistema", json.errores);
        }
      })
      .catch((error) => {
        this.props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
        this.setState({ cargando: "FIN" });
        this.uncheckOnError();
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.loguearse();
    } else {
      this.setState({});
    }
  };

  render() {
    const jsxCaptcha = (
      <div>
        {process.env.REACT_APP_ACTIVAR_CAPTCHA_LOGIN === "si" && (
          <div style={{ transform: "scale(0.96)", transformOrigin: "0 0" }}>
            <Recaptcha
              ref={(e) => (this.recaptchaInstance = e)}
              sitekey={process.env.REACT_APP_SITE_KEY_CAPTCHA}
              render="explicit"
              hl={"es"}
              size="normal"
              onloadCallback={this.onLoadRecaptcha}
              verifyCallback={this.verifyCallback}
              expiredCallback={this.onExpiroCaptcha}
            />
            <br></br>
            <span
              className="text-danger text-small"
              style={{ position: "relative", top: "-15px" }}
            >
              {this.state.errors.captcha}
            </span>
          </div>
        )}
      </div>
    );

    return (
      <div>
        <Desktop>
          <div className="loginBody row" id="desktopLogin">
            {this.state.logueado === false && (
              <Animated
                className={
                  ["13", "43", "20", "50", "23", "18", "48"].includes(
                    process.env.REACT_APP_COD_ORGANIZACION
                  )
                    ? //? "change-p desktopLogin bg-prov- bg-provrndesk-" +
                      `change-p desktopLogin bg-prov-${process.env.REACT_APP_COD_ORGANIZACION} bg-provrndesk-${process.env.REACT_APP_COD_ORGANIZACION}` //+
                    : //process.env.REACT_APP_COD_ORGANIZACION
                      "change-p desktopLogin bg-prov-" +
                      process.env.REACT_APP_COD_ORGANIZACION
                }
                animationIn=""
                isVisible={true}
                style={{
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  position: "absolute",
                }}
              >
                <div className="row pb-3 mb-4 pr-3">
                  <div className="col-sm-12">
                    <div className="text-right mt-2">
                      <span
                        className={
                          "text-left header-text mr-2 bg-login-prov-" +
                          process.env.REACT_APP_COD_ORGANIZACION
                        }
                      >
                        Un producto de{" "}
                      </span>
                      <img
                        src={this.state.logoOrg}
                        id="logoProducto"
                        style={{ marginTop: "-5px" }}
                        width="7%"
                      />
                    </div>
                    {["53", "23"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    ) ? (
                      <div>
                        <img
                          src={logoLeyenda}
                          alt="logoLeyenda"
                          style={{
                            position: "absolute",
                            height: "6em",
                            right: "12%",
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <img
                      src={this.state.loginImg}
                      id="logoIndia"
                      className={
                        "img-provrndesk-" +
                        process.env.REACT_APP_COD_ORGANIZACION
                      }
                    />
                  </div>

                  <div className="col-sm-6" style={{ marginTop: "4%" }}>
                    <Form
                      autoComplete="off"
                      className={
                        "p-3 mt-4 form-provrndesk-" +
                        process.env.REACT_APP_COD_ORGANIZACION
                      }
                      id={
                        ["20", "50", "18", "48"].includes(
                          process.env.REACT_APP_COD_ORGANIZACION
                        )
                          ? "formLogin-" +
                            process.env.REACT_APP_COD_ORGANIZACION
                          : "formLogin"
                      }
                      onSubmit={this.handleSubmit}
                      noValidate
                    >
                      {["42", "12"].includes(
                        process.env.REACT_APP_COD_ORGANIZACION
                      ) && (
                        <h2 className="atendeLaSuerte header-text">
                          Si la <span className="m-bold">suerte </span>
                          <br /> te llama <br />
                          <span className="m-bold">atendela!</span>{" "}
                        </h2>
                      )}

                      {/* {["23", "53"].includes(
                        process.env.REACT_APP_COD_ORGANIZACION
                      ) && (
                        <h2 className="atendeLaSuerte pt-4 header-text m-bold">
                          <span style={{ fontSize: "2.2rem" }}>DIVERTITE</span>{" "}
                          <br />
                          JUGÁ Y GANÁ
                          <br />{" "}
                        </h2>
                      )} */}

                      <div className="pos-rel">
                        <FormGroup
                          autoComplete="off"
                          className="textImput"
                          style={{ marginTop: "1rem" }}
                        >
                          <Input
                            autoComplete="off"
                            type="text"
                            value={this.state.nroDocu}
                            name="nroDocu"
                            id="nroDocu"
                            className="form-control"
                            placeholder="&#128100; dni o email"
                            data-inline="false"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                        <span
                          className="text-danger text-small"
                          style={{
                            position: "absolute",
                            top: "105%",
                            left: "1%",
                          }}
                        >
                          {this.state.errors.nroDocu}
                        </span>
                      </div>

                      <div className="pos-rel">
                        <FormGroup
                          autoComplete="off"
                          className="mt-4 textImput"
                          style={{ marginBottom: "3.8rem" }}
                        >
                          <div
                            className="input-group bg-light"
                            style={{ borderRadius: "0.25rem" }}
                          >
                            <Input
                              className="form-control mr-0 text-pass"
                              value={this.state.clave}
                              autoComplete="off"
                              type="text"
                              name="clave"
                              id="clave"
                              placeholder="&#x1f512;"
                              onCopy={(e) => {
                                e.preventDefault();
                                e.nativeEvent.stopImmediatePropagation();
                              }}
                              onCut={(e) => {
                                e.preventDefault();
                                e.nativeEvent.stopImmediatePropagation();
                              }}
                              onChange={this.handleChange}
                            />

                            <div
                              onClick={this.handlePassView}
                              className="pos-rel"
                              id="eye"
                              style={{ width: "15%", borderRadius: "0.25rem" }}
                            >
                              <Animated
                                style={{
                                  position: "absolute",
                                  top: "20%",
                                  right: "65%",
                                  width: "10%",
                                }}
                                animationIn="fadeIn"
                                animationInDuration={200}
                                animationOutDuration={200}
                                animateOnMount={false}
                                isVisible={!this.state.pass}
                              >
                                <span
                                  id="eye"
                                  data-width="20px"
                                  className="iconify op-text"
                                  data-icon="eva:eye-off-2-outline"
                                  data-inline="false"
                                ></span>
                              </Animated>

                              <Animated
                                style={{
                                  position: "absolute",
                                  top: "20%",
                                  right: "65%",
                                  width: "10%",
                                }}
                                animationIn="fadeIn"
                                animationInDuration={200}
                                animationOutDuration={200}
                                animateOnMount={false}
                                isVisible={this.state.pass}
                              >
                                <span
                                  id="eye"
                                  data-width="20px"
                                  className="iconify op-text"
                                  data-icon="eva:eye-outline"
                                  data-inline="false"
                                ></span>
                              </Animated>
                            </div>
                          </div>

                          <span
                            className="op-text"
                            id="pasLabel"
                            style={{
                              textTransform: "lowercase",
                              position: "absolute",
                              top: "20%",
                              left: "12%",
                              zIndex: "3",
                            }}
                          >
                            contraseña
                          </span>
                        </FormGroup>
                        <span
                          className="text-danger text-small"
                          style={{
                            position: "absolute",
                            top: "105%",
                            left: "1%",
                          }}
                        >
                          {this.state.errors.clave}
                        </span>
                      </div>

                      <FormGroup check className="recordarme mt-4">
                        <label className="switch" style={{ color: "#000" }}>
                          <input
                            checked={this.state.checked}
                            type="checkbox"
                            id="remember_me"
                            onChange={() => {
                              this.props.handleRemember("toggle");
                              this.uncheck();
                            }}
                          />
                          <span
                            className={
                              ["20", "50", "48", "18"].includes(
                                process.env.REACT_APP_COD_ORGANIZACION
                              )
                                ? "sliderLogin-" +
                                  process.env.REACT_APP_COD_ORGANIZACION +
                                  " round"
                                : "sliderLogin round"
                            }
                          ></span>
                        </label>
                        <div
                          style={
                            ["20", "50", "18", "48"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? { color: "white" }
                              : { opacity: "80%", color: "black" }
                          }
                          className="mt-2 ml-2"
                        >
                          Recordarme
                        </div>
                      </FormGroup>

                      {/* <FormGroup>
											<div id="IngresoCaptcha" tabIndex="1">	
												{jsxCaptcha}
											</div>
									</FormGroup> */}

                      <div className="row text-center textLogin mt-4">
                        <Button
                          disabled={this.state.cargando === "EJECUTANDO"}
                          className={
                            "boton btn-prov-" +
                            process.env.REACT_APP_COD_ORGANIZACION
                          }
                          id={
                            ["20", "50"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? "botonLogin-" +
                                process.env.REACT_APP_COD_ORGANIZACION
                              : "botonLogin"
                          }
                          style={{ height: "2.333em" }}
                        >
                          {this.state.cargando === "EJECUTANDO" && (
                            <div className="animate-flicker">
                              <b>Cargando</b>
                            </div>
                          )}

                          {(this.state.cargando === "INICIO" ||
                            this.state.cargando === "FIN") && (
                            <span>INGRESAR</span>
                          )}
                        </Button>
                      </div>

                      <div
                        className="textLogin mt-4 resetPass"
                        onClick={() => {
                          this.setState({ envioGeneracion: false });
                          $(".nroDocuOlvide").val("");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          data-toggle="modal"
                          data-target="#olvideModal"
                          style={
                            ["20", "50", "18", "48"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? { color: "white" }
                              : { color: "black" }
                          }
                        >
                          ¿Olvidaste tu contraseña?
                        </div>
                      </div>

                      <div className="text-center mt-4 registrarse">
                        <span
                          style={
                            ["20", "50", "18", "48"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? { color: "white" }
                              : {
                                  opacity: "80%",
                                  color: "black",
                                  cursor: "pointer",
                                }
                          }
                          className="textLogin olvidaste "
                          onClick={this.irARegistracion}
                        >
                          REGISTRARSE
                        </span>
                      </div>
                      <div className="text-center responsabilidad">
                        <span
                          style={{
                            opacity: "80%",
                            color: "black",
                            fontSize: "1.3vh",
                            cursor: "pointer",
                          }}
                          className="olvidaste responsabilidad"
                        >
                          {["42", "12"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          ) ? (
                            <>
                              {" "}
                              <a
                                href="https://ijan-neuquen.com/juego_responsable.php"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Juguemos con responsabilidad y en sitios
                                oficiales
                              </a>
                            </>
                          ) : (
                            <p
                              style={
                                ["20", "50", "18", "48"].includes(
                                  process.env.REACT_APP_COD_ORGANIZACION
                                )
                                  ? { color: "white" }
                                  : { opacity: "80%", color: "black" }
                              }
                            >
                              Juguemos con responsabilidad y en sitios oficiales
                            </p>
                          )}
                        </span>
                      </div>
                    </Form>
                  </div>
                </div>

                <footer className="row" id="footerWeb" hidden>
                  <div>
                    <div id="borderExterior">
                      <p className="col condicionesNqn">
                        www.laneuquinaonline.com.ar pertenece a Lorem Ipsum is
                        simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries,
                        but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s
                        with the release of Letraset sheets containing Lorem
                        Ipsum passages, and more recently with desktop
                        publishing software like Aldus PageMaker including
                        versions of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                </footer>
              </Animated>
            )}

            {/*---------------------------------------MODAL OLVIDE PASS-----------------------------------------------------*/}

            <div
              style={{ position: "fixed", top: "8%" }}
              className="modal fade pb-5"
              id="olvideModal"
              role="dialog"
              aria-labelledby="olvideModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="olvideModalLabel">
                      Generar nueva clave
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body text-center pt-0">
                    <OlvideClave
                      uncheck={this.uncheck}
                      handleRemember={this.props.handleRemember}
                      generoNuevaClave={this.generoNuevaClave}
                      nroDocuEnviado={this.state.envioGeneracion}
                      notificacionMensajes={this.props.notificacionMensajes}
                      actuVersionSiCorresponde={
                        this.props.actuVersionSiCorresponde
                      }
                      mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                      mensajeErrorWS={this.props.mensajeErrorWS}
                      cerrarSesion={this.props.cerrarSesion}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Desktop>

        <Tablet>
          <div className="tabletVertical login">
            <div className="loginBody">
              {this.state.logueado === false && (
                <Animated
                  className={
                    (process.env.REACT_APP_COD_ORGANIZACION === "43" && "13") ||
                    process.env.REACT_APP_COD_ORGANIZACION === "20" ||
                    process.env.REACT_APP_COD_ORGANIZACION === "50" ||
                    process.env.REACT_APP_COD_ORGANIZACION === "18" ||
                    process.env.REACT_APP_COD_ORGANIZACION === "48"
                      ? "bg-provrn-" + process.env.REACT_APP_COD_ORGANIZACION
                      : "change-p bg-prov-" +
                        process.env.REACT_APP_COD_ORGANIZACION
                  }
                  animationIn=""
                  isVisible={true}
                  style={{
                    top: "0",
                    bottom: "-10rem",
                    left: "0",
                    right: "0",
                    position: "absolute",
                  }}
                >
                  <div
                    className={
                      (process.env.REACT_APP_COD_ORGANIZACION === "43" &&
                        "13") ||
                      process.env.REACT_APP_COD_ORGANIZACION === "20" ||
                      process.env.REACT_APP_COD_ORGANIZACION === "50" ||
                      process.env.REACT_APP_COD_ORGANIZACION === "18" ||
                      process.env.REACT_APP_COD_ORGANIZACION === "48"
                        ? " bg-provrn-" + process.env.REACT_APP_COD_ORGANIZACION
                        : "pageFlow-login loginForm bg-prov-" +
                          process.env.REACT_APP_COD_ORGANIZACION
                    }
                  >
                    <Form
                      autoComplete="off"
                      className="p-3 mt-4 text-center"
                      onSubmit={this.handleSubmit}
                      noValidate
                    >
                      <img src={this.state.logoSplash} id="" className="mt-2" />

                      <div className="pos-rel">
                        <FormGroup
                          autoComplete="off"
                          className="mt-4 textImput"
                        >
                          {/* &#128100; */}
                          <Input
                            autoComplete="off"
                            type="text"
                            value={this.state.nroDocu}
                            name="nroDocu"
                            id="nroDocu"
                            placeholder="&#128100; dni o email"
                            data-inline="false"
                            onChange={this.handleChange}
                          />
                        </FormGroup>
                        <span
                          className="text-danger text-small"
                          style={{
                            position: "absolute",
                            top: "105%",
                            left: "1%",
                          }}
                        >
                          {this.state.errors.nroDocu}
                        </span>
                      </div>

                      <div className="pos-rel">
                        <FormGroup
                          autoComplete="off"
                          className="mt-4 textImput"
                        >
                          <div className="input-group bg-light">
                            <Input
                              className="form-control mr-0 text-pass"
                              value={this.state.clave}
                              autoComplete="off"
                              type="text"
                              name="clave"
                              id="clave"
                              placeholder="&#x1f512;"
                              onCopy={(e) => {
                                e.preventDefault();
                                e.nativeEvent.stopImmediatePropagation();
                              }}
                              onCut={(e) => {
                                e.preventDefault();
                                e.nativeEvent.stopImmediatePropagation();
                              }}
                              onChange={this.handleChange}
                            />
                            <div
                              onClick={this.handlePassView}
                              className="pos-rel"
                              id="eye"
                              style={{ width: "15%" }}
                            >
                              <Animated
                                style={{
                                  position: "absolute",
                                  top: "22%",
                                  right: "30%",
                                }}
                                animationIn="fadeIn"
                                animationInDuration={200}
                                animationOutDuration={200}
                                animateOnMount={false}
                                isVisible={!this.state.pass}
                              >
                                <span
                                  id="eye"
                                  data-width="20px"
                                  className="iconify op-text"
                                  data-icon="eva:eye-off-2-outline"
                                  data-inline="false"
                                ></span>
                              </Animated>

                              <Animated
                                style={{
                                  position: "absolute",
                                  top: "22%",
                                  right: "30%",
                                }}
                                animationIn="fadeIn"
                                animationInDuration={200}
                                animationOutDuration={200}
                                animateOnMount={false}
                                isVisible={this.state.pass}
                              >
                                <span
                                  id="eye"
                                  data-width="20px"
                                  className="iconify op-text"
                                  data-icon="eva:eye-outline"
                                  data-inline="false"
                                ></span>
                              </Animated>
                            </div>
                          </div>

                          <span
                            className="op-text"
                            id="pasLabel"
                            style={{
                              textTransform: "lowercase",
                              position: "absolute",
                              top: "22%",
                              left: "3rem",
                              zIndex: "3",
                            }}
                          >
                            contraseña
                          </span>
                        </FormGroup>
                        <span
                          className="text-danger text-small"
                          style={{
                            position: "absolute",
                            top: "105%",
                            left: "1%",
                          }}
                        >
                          {this.state.errors.clave}
                        </span>
                      </div>

                      <FormGroup check className="recordarme mt-4">
                        <label className="switch">
                          <input
                            checked={this.state.checked}
                            type="checkbox"
                            id="remember_me"
                            onChange={() => {
                              this.props.handleRemember("toggle");
                              this.uncheck();
                            }}
                          />
                          <span
                            className={
                              ["20", "50", "18", "48"].includes(
                                process.env.REACT_APP_COD_ORGANIZACION
                              )
                                ? "sliderLogin-" +
                                  process.env.REACT_APP_COD_ORGANIZACION +
                                  " round"
                                : "sliderLogin round"
                            }
                          ></span>
                        </label>
                        <div
                          style={
                            ["20", "50", "18", "48"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? { color: "white" }
                              : { opacity: "80%", color: "black" }
                          }
                          className="mt-2 ml-2"
                        >
                          Recordarme
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div id="IngresoCaptcha" tabIndex="1">
                          {jsxCaptcha}
                        </div>
                      </FormGroup>

                      <div className="row text-center textLogin mt-4">
                        <Button
                          className="boton"
                          id={
                            ["20", "50", "18", "48"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? "botonLogin-" +
                                process.env.REACT_APP_COD_ORGANIZACION
                              : "botonLogin"
                          }
                        >
                          {this.state.cargando === "EJECUTANDO" && (
                            <div className="animate-flicker">
                              <b>Cargando</b>
                            </div>
                          )}

                          {(this.state.cargando === "INICIO" ||
                            this.state.cargando === "FIN") && (
                            <span>INGRESAR</span>
                          )}
                        </Button>
                      </div>
                      <div
                        className="textLogin mt-4"
                        onClick={() => {
                          this.setState({ envioGeneracion: false });
                          $(".nroDocuOlvide").val("");
                        }}
                        style={
                          ["20", "50", "18", "48"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          )
                            ? { color: "white" }
                            : { color: "black" }
                        }
                      >
                        <div data-toggle="modal" data-target="#olvideModal">
                          ¿Olvidaste tu contraseña?
                        </div>
                      </div>

                      <div
                        className="text-center mb-3 mt-4 registrarse"
                        onClick={this.irARegistracion}
                      >
                        <span
                          style={
                            ["20", "50", "18", "48"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? { color: "white" }
                              : {
                                  opacity: "80%",
                                  color: "black",
                                  cursor: "pointer",
                                }
                          }
                          className="textLogin olvidaste "
                        >
                          REGISTRARSE
                        </span>
                      </div>
                      <div className="text-center responsabilidad">
                        <span
                          style={{
                            opacity: "80%",
                            color: "black",
                            fontSize: "2vh",
                          }}
                          className="olvidaste responsabilidad"
                        >
                          {["42", "12"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          ) ? (
                            <>
                              {" "}
                              <a
                                href="https://ijan-neuquen.com/juego_responsable.php"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Juguemos con responsabilidad y en sitios
                                oficiales
                              </a>
                            </>
                          ) : (
                            <p
                              style={
                                ["20", "50", "18", "48"].includes(
                                  process.env.REACT_APP_COD_ORGANIZACION
                                )
                                  ? { color: "white" }
                                  : { opacity: "80%", color: "black" }
                              }
                            >
                              Juguemos con responsabilidad y en sitios oficiales
                            </p>
                          )}
                        </span>
                      </div>
                    </Form>

                    {/* <div className="text-center text-x-small header-text"> 
								<div>
									<span className="iconify mr-1 mb-1" data-icon="akar-icons:whatsapp-fill" data-inline="false" data-width="15px"></span> 
									<span>299 592-9406 / 9420</span> 		
								</div>
								<div>
									<span className="iconify mr-1" data-icon="ant-design:mail-outlined" data-inline="false" data-width="15px"></span>
									<span>contacto@laneuquinaonline.com.ar</span>
								</div>
							</div>
							*/}
                  </div>
                </Animated>
              )}

              {/*---------------------------------------MODAL OLVIDE PASS-----------------------------------------------------*/}

              <div
                style={{ position: "fixed", top: "8%" }}
                className="modal fade pb-5"
                id="olvideModal"
                role="dialog"
                aria-labelledby="olvideModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="olvideModalLabel">
                        Generar nueva clave
                      </h5>

                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body text-center pt-0">
                      <OlvideClave
                        uncheck={this.uncheck}
                        handleRemember={this.props.handleRemember}
                        generoNuevaClave={this.generoNuevaClave}
                        nroDocuEnviado={this.state.envioGeneracion}
                        notificacionMensajes={this.props.notificacionMensajes}
                        actuVersionSiCorresponde={
                          this.props.actuVersionSiCorresponde
                        }
                        mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                        mensajeErrorWS={this.props.mensajeErrorWS}
                        cerrarSesion={this.props.cerrarSesion}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </Tablet>

        <Mobile className="soyvertical">
          <div className="loginBody">
            {this.state.logueado === false && (
              <Animated
                className={
                  (process.env.REACT_APP_COD_ORGANIZACION === "43" && "13") ||
                  process.env.REACT_APP_COD_ORGANIZACION === "20" ||
                  process.env.REACT_APP_COD_ORGANIZACION === "50" ||
                  process.env.REACT_APP_COD_ORGANIZACION === "18" ||
                  process.env.REACT_APP_COD_ORGANIZACION === "48"
                    ? "bg-provrn-" + process.env.REACT_APP_COD_ORGANIZACION
                    : "change-p bg-prov-" +
                      process.env.REACT_APP_COD_ORGANIZACION
                }
                animationIn=""
                isVisible={true}
                style={{
                  top: "0",
                  bottom: "-10rem",
                  left: "0",
                  right: "0",
                  position: "absolute",
                }}
              >
                <div
                  className={
                    ["13", "43", "20", "50", "18", "48"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    )
                      ? "pageFlow-login loginForm bg-provrn-" +
                        process.env.REACT_APP_COD_ORGANIZACION
                      : "pageFlow-login loginForm bg-prov-" +
                        process.env.REACT_APP_COD_ORGANIZACION
                  }
                >
                  <Form
                    autoComplete="off"
                    className="p-3 mt-4 text-center"
                    onSubmit={this.handleSubmit}
                    noValidate
                  >
                    <img
                      src={this.state.loginMImg}
                      style={
                        process.env.REACT_APP_COD_ORGANIZACION === "20" ||
                        process.env.REACT_APP_COD_ORGANIZACION === "50"
                          ? { width: "100%" }
                          : {}
                      }
                      id=""
                      className="mt-2"
                    />

                    <div className="pos-rel">
                      <FormGroup autoComplete="off" className="mt-4 textImput">
                        {/* &#128100; */}
                        <Input
                          autoComplete="off"
                          type="text"
                          value={this.state.nroDocu}
                          name="nroDocu"
                          id="nroDocu"
                          placeholder="&#128100; dni o email"
                          data-inline="false"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                      <span
                        className="text-danger text-small"
                        style={{
                          position: "absolute",
                          top: "105%",
                          left: "1%",
                        }}
                      >
                        {this.state.errors.nroDocu}
                      </span>
                    </div>

                    <div className="pos-rel">
                      <FormGroup autoComplete="off" className="mt-4 textImput">
                        <div className="input-group bg-light">
                          <Input
                            className="form-control mr-0 text-pass"
                            value={this.state.clave}
                            autoComplete="off"
                            type="text"
                            name="clave"
                            id="clave"
                            placeholder="&#x1f512;"
                            onCopy={(e) => {
                              e.preventDefault();
                              e.nativeEvent.stopImmediatePropagation();
                            }}
                            onCut={(e) => {
                              e.preventDefault();
                              e.nativeEvent.stopImmediatePropagation();
                            }}
                            onChange={this.handleChange}
                          />
                          <div
                            onClick={this.handlePassView}
                            className="pos-rel"
                            id="eye"
                            style={{ width: "15%" }}
                          >
                            <Animated
                              style={{
                                position: "absolute",
                                top: "22%",
                                right: "30%",
                              }}
                              animationIn="fadeIn"
                              animationInDuration={200}
                              animationOutDuration={200}
                              animateOnMount={false}
                              isVisible={!this.state.pass}
                            >
                              <span
                                id="eye"
                                data-width="20px"
                                className="iconify op-text"
                                data-icon="eva:eye-off-2-outline"
                                data-inline="false"
                              ></span>
                            </Animated>

                            <Animated
                              style={{
                                position: "absolute",
                                top: "22%",
                                right: "30%",
                              }}
                              animationIn="fadeIn"
                              animationInDuration={200}
                              animationOutDuration={200}
                              animateOnMount={false}
                              isVisible={this.state.pass}
                            >
                              <span
                                id="eye"
                                data-width="20px"
                                className="iconify op-text"
                                data-icon="eva:eye-outline"
                                data-inline="false"
                              ></span>
                            </Animated>
                          </div>
                        </div>

                        <span
                          className="op-text"
                          id="pasLabel"
                          style={{
                            textTransform: "lowercase",
                            position: "absolute",
                            top: "22%",
                            left: "11%",
                            zIndex: "3",
                          }}
                        >
                          contraseña
                        </span>
                      </FormGroup>
                      <span
                        className="text-danger text-small"
                        style={{
                          position: "absolute",
                          top: "105%",
                          left: "1%",
                        }}
                      >
                        {this.state.errors.clave}
                      </span>
                    </div>

                    <FormGroup check className="recordarme mt-4">
                      <label className="switch">
                        <input
                          checked={this.state.checked}
                          type="checkbox"
                          id="remember_me"
                          onChange={() => {
                            this.props.handleRemember("toggle");
                            this.uncheck();
                          }}
                        />
                        <span
                          className={
                            ["20", "50", "18", "48"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                              ? "sliderLogin-" +
                                process.env.REACT_APP_COD_ORGANIZACION +
                                " round"
                              : "sliderLogin round"
                          }
                        ></span>
                      </label>
                      <div
                        style={
                          ["20", "50", "18", "48"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          )
                            ? { color: "white" }
                            : { opacity: "80%", color: "black" }
                        }
                        className="mt-2 ml-2"
                      >
                        Recordarme
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <div id="IngresoCaptcha" tabIndex="1">
                        {jsxCaptcha}
                      </div>
                    </FormGroup>

                    <div className="row text-center textLogin mt-4">
                      <Button
                        className="boton btn-login-mob"
                        id={
                          ["20", "50", "18", "48"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          )
                            ? "botonLogin-" +
                              process.env.REACT_APP_COD_ORGANIZACION
                            : "botonLogin"
                        }
                      >
                        {this.state.cargando === "EJECUTANDO" && (
                          <div className="animate-flicker">
                            <b>Cargando</b>
                          </div>
                        )}

                        {(this.state.cargando === "INICIO" ||
                          this.state.cargando === "FIN") && (
                          <span>INGRESAR</span>
                        )}
                      </Button>
                    </div>
                    <div
                      className="textLogin mt-4"
                      onClick={() => {
                        this.setState({ envioGeneracion: false });
                        $(".nroDocuOlvide").val("");
                      }}
                      style={
                        ["20", "50", "18", "48"].includes(
                          process.env.REACT_APP_COD_ORGANIZACION
                        )
                          ? { color: "white" }
                          : { color: "black" }
                      }
                    >
                      <div data-toggle="modal" data-target="#olvideModal">
                        ¿Olvidaste tu contraseña?
                      </div>
                    </div>

                    <div
                      className="text-center mb-3 mt-4 registrarse"
                      onClick={this.irARegistracion}
                    >
                      <span
                        style={
                          ["20", "50", "18", "48"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          )
                            ? { color: "white" }
                            : {
                                opacity: "80%",
                                color: "black",
                                cursor: "pointer",
                              }
                        }
                        className="textLogin olvidaste "
                      >
                        REGISTRARSE
                      </span>
                    </div>
                    <div className="text-center responsabilidad">
                      <span
                        style={{
                          opacity: "80%",
                          color: "black",
                          fontSize: "1.5vh",
                        }}
                        className="olvidaste responsabilidad"
                      >
                        {["42", "12"].includes(
                          process.env.REACT_APP_COD_ORGANIZACION
                        ) ? (
                          <>
                            {" "}
                            <a
                              href="https://ijan-neuquen.com/juego_responsable.php"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Juguemos con responsabilidad y en sitios oficiales
                            </a>
                          </>
                        ) : (
                          <div className="container">
                            <div className="row justify-content-center">
                              <div className="col-12 pt-2">
                                <p
                                  style={
                                    ["20", "50", "18", "48"].includes(
                                      process.env.REACT_APP_COD_ORGANIZACION
                                    )
                                      ? { color: "white" }
                                      : { opacity: "80%", color: "black" }
                                  }
                                  App
                                  Login
                                >
                                  Juguemos con responsabilidad
                                  <br />y en sitios oficiales
                                </p>
                              </div>
                              <div className="col-12">
                                {["20", "50"].includes(
                                  process.env.REACT_APP_COD_ORGANIZACION
                                ) && <img src={this.state.logoApp} />}
                              </div>
                              <div className="col-2">
                                {["18", "48"].includes(
                                  process.env.REACT_APP_COD_ORGANIZACION
                                ) && (
                                  <img
                                    style={{ width: "3rem" }}
                                    src={logoJugaXJugar}
                                  />
                                )}
                              </div>
                              <div className="col-10">
                                {["18", "48"].includes(
                                  process.env.REACT_APP_COD_ORGANIZACION
                                ) && (
                                  <img
                                    style={{
                                      width: "5rem",
                                      marginRight: "2rem",
                                    }}
                                    src={this.state.logoOrg}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </span>
                    </div>
                  </Form>

                  {/* <div className="text-center text-x-small header-text"> 
							<div>
								<span className="iconify mr-1 mb-1" data-icon="akar-icons:whatsapp-fill" data-inline="false" data-width="15px"></span> 
								<span>299 592-9406 / 9420</span> 		
							</div>
							<div>
								<span className="iconify mr-1" data-icon="ant-design:mail-outlined" data-inline="false" data-width="15px"></span>
								<span>contacto@laneuquinaonline.com.ar</span>
							</div>
						</div>
						*/}
                </div>
              </Animated>
            )}

            {/*---------------------------------------MODAL OLVIDE PASS-----------------------------------------------------*/}

            <div
              style={{ position: "fixed", top: "8%" }}
              className="modal fade pb-5"
              id="olvideModal"
              role="dialog"
              aria-labelledby="olvideModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="olvideModalLabel">
                      Generar nueva clave
                    </h5>

                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>

                  <div className="modal-body text-center pt-0">
                    <OlvideClave
                      uncheck={this.uncheck}
                      handleRemember={this.props.handleRemember}
                      generoNuevaClave={this.generoNuevaClave}
                      nroDocuEnviado={this.state.envioGeneracion}
                      notificacionMensajes={this.props.notificacionMensajes}
                      actuVersionSiCorresponde={
                        this.props.actuVersionSiCorresponde
                      }
                      mensajeErrorGeneral={this.props.mensajeErrorGeneral}
                      mensajeErrorWS={this.props.mensajeErrorWS}
                      cerrarSesion={this.props.cerrarSesion}
                    />
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
          </div>
        </Mobile>

        {/* <MobileHorizontal>
					<img src={this.state.logoOrg} id="modoVertical"  />
				</MobileHorizontal>  */}
      </div>
    );
  }
}

export default withRouter(Login);
