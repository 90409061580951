import React from "react";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import { DivColRetiro, DivRow, H4Retiro } from "./PaginaSolicitudRetiro";
import { estilosProv } from "../../configProvincias";
import ExtractionInProgressSalta from "../../components/salta/ExtractionInProgressSalta";

const SolicitudEnCurso = ({
  logoRetiro,
  solicitud,
  direccionLoteria,
  clickCancelarSolicitud,
  clickGenerarQRSolRetiro,
}) => {
  const colorProv = estilosProv[process.env.REACT_APP_COD_ORGANIZACION].fondo;

  if (["23", "53"].includes(process.env.REACT_APP_COD_ORGANIZACION))
    return (
      <ExtractionInProgressSalta
        solicitud={solicitud}
        direccionLoteria={direccionLoteria}
        clickCancelarSolicitud={clickCancelarSolicitud}
        clickGenerarQRSolRetiro={clickGenerarQRSolRetiro}
      />
    );

  return (
    <div className="container-fluid">
      <DivRow className="row justify-content-center mt-4"></DivRow>
      <DivRow className="row justify-content-center">
        <div className="col-12">
          <span className="m-bold">Fecha solicitud:</span>{" "}
          {solicitud.fechaPedido}
        </div>
        <div className="col-12">
          <span className="m-bold">Vigente hasta:</span>{" "}
          {solicitud.fechaCaducidad != null
            ? solicitud.fechaCaducidad.substring(0, 10)
            : ""}
        </div>
      </DivRow>
      <DivRow className="row justify-content-center">
        <div className="col-12">
          <Animated
            animationIn="zoomIn"
            animationInDelay={800}
            animationInDuration={500}
          >
            <div>
              <span
                className="iconify text-success"
                data-icon="el:ok-circle"
                data-inline="false"
                data-width="60px"
              ></span>
            </div>
          </Animated>
        </div>
        <div className="col-12">
          {solicitud != null &&
            (solicitud.diasEsperaRetiro == null ||
              solicitud.diasEsperaRetiro === 0) && (
              <div>
                <h6>
                  Ya podes <span className="m-bold">retirar</span>{" "}
                </h6>
                <h5 className="text-success m-bold">
                  <NumberFormat
                    value={solicitud.importe}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                  />
                </h5>
              </div>
            )}
        </div>
        <div className="col-12">
          {solicitud != null &&
            solicitud.diasEsperaRetiro != null &&
            solicitud.diasEsperaRetiro === 1 && (
              <div>
                <div>
                  Podrás <span className="m-bold">retirar</span>{" "}
                </div>
                <div style={{ fontSize: "20px" }}>
                  <NumberFormat
                    value={solicitud.importe}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                  />
                </div>
              </div>
            )}
        </div>
        <div className="col-12">
          {solicitud != null &&
            solicitud.diasEsperaRetiro != null &&
            solicitud.diasEsperaRetiro > 1 && (
              <div>
                Podrás retirar{" "}
                <b style={{ fontSize: "20px" }}>
                  <NumberFormat
                    value={solicitud.importe}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    isNumericString={true}
                  />
                </b>
                <br></br>
              </div>
            )}
        </div>
        <div className="col-12">
          {solicitud.retiraEnLoteria != true && (
            <span>
              {/* en la <span className="m-bold">Agencia n° {solicitud.agencia!=null?solicitud.agencia.descripcion:""} </span>
                                            <br/> 
                                            <span className="op-text text-small">{solicitud.agencia!=null?solicitud.agencia.direccion:""} {solicitud.agencia!=null?"-":""} {solicitud.agencia!=null?solicitud.agencia.localidad:""}</span> */}
              acercándote a una <span className="m-bold">agencia oficial</span>
            </span>
          )}
        </div>
        <div className="col-12">
          {solicitud.retiraEnLoteria === true && (
            <span>
              dirigiéndote a la{" "}
              <b>Loteria de {process.env.REACT_APP_NOMBRE_PROVINCIA}</b>
              <br /> {direccionLoteria}
            </span>
          )}
        </div>
        {/* <div className="col-12">
          {solicitud.retiraEnLoteria === true && (
            <span>
              dirigiéndote a la{" "}
              <b>Loteria de {process.env.REACT_APP_NOMBRE_PROVINCIA}</b>
              <br /> {direccionLoteria}
            </span>
          )}
        </div> */}
        <div className="col-12">
          {solicitud != null &&
            solicitud.diasEsperaRetiro != null &&
            solicitud.diasEsperaRetiro > 1 && (
              <div>
                a los {solicitud.diasEsperaRetiro} días hábiles posterior
                <br></br>a la fecha de solicitud
              </div>
            )}
        </div>
        <DivColGenerarQR className="col-6">
          <button
            id="botonGenerarQR"
            data-toggle="modal"
            data-target="#qrModal"
            className={
              ["20", "50"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                ? "btn qr-btn-prov-" + process.env.REACT_APP_COD_ORGANIZACION
                : "btn btn-info"
            }
            onClick={clickGenerarQRSolRetiro}
          >
            GENERAR QR
          </button>
        </DivColGenerarQR>
        <DivColCancelar className="col-6">
          <button
            type="button"
            className={
              ["20", "50"].includes(process.env.REACT_APP_COD_ORGANIZACION)
                ? "btn qr-btn-cancel-prov-" +
                  process.env.REACT_APP_COD_ORGANIZACION
                : "btn btn-danger"
            }
            data-toggle="modal"
            onClick={() => {
              clickCancelarSolicitud();
            }}
          >
            CANCELAR
          </button>
        </DivColCancelar>

        <div className="col-12">
          <p className="op-text mt-2" style={{ fontSize: "12px" }}>
            Cuando estés en la agencia generá tu
            <br />
            <span className="m-bold">QR de identificación</span>, el mismo te
            será solicitado.
          </p>
        </div>
      </DivRow>
    </div>
  );
};

export default SolicitudEnCurso;

const DivColGenerarQR = styled.div`
  text-align-last: end;
`;

const DivColCancelar = styled.div`
  text-align: start;
`;
