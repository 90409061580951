import React from "react";
import styled from "styled-components";
import FullModal from "./FullModal";
import someImage from "../../../src/img/LOGO_SPLASH_53.png";

const links = [
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/tombola/",
    label: "Tómbola",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/la-changuita/",
    label: "La Changuita",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/tombola-total/",
    label: "Tómbola Total",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/pozo-de-la-quiniela/",
    label: "Pozo de la Quiniela",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/loto/",
    label: "Loto Plus",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/quini-6/",
    label: "Quini 6",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/brinco/",
    label: "Brinco",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/loto-5/",
    label: "Loto 5 Plus",
    image: "",
  },
  {
    href: "https://www.loteriadesalta.com/extractos-oficiales/telekino/",
    label: "Telekino",
    image: "",
  },
];

const ModalResultsSalta = (props) => {
  return (
    <FullModal open={props.open} handleClose={props.handleClose}>
      <>
        <Image src={someImage} alt="someImage" />
        <h3 style={{ textAlign: "center" }} className="m-bold">
          Últimos resultados
        </h3>

        <LinkContainer>
          <div className="row">
            {links.map((link) => (
              <div
                className="col-12 col-md-6"
                key={link.href}
                style={{ margin: "10px 0" }}
              >
                <Link target="_blank" href={link.href}>
                  <span
                    className="iconify"
                    data-icon="fluent:lottery-24-regular"
                    data-inline="false"
                    data-width="25px"
                  ></span>
                  {link.label}
                  <span
                    className="iconify"
                    data-icon="akar-icons:link-out"
                    data-inline="false"
                    data-width="20px"
                  ></span>
                </Link>
              </div>
            ))}
          </div>
        </LinkContainer>
      </>
    </FullModal>
  );
};

export default ModalResultsSalta;

const LinkContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  max-width: 600px;

  background: white;
  padding: 25px;
  border-radius: 16px;
  box-shadow: 8px 8px 7px 2px rgba(40, 38, 38, 0.29);
`;

const Image = styled.img`
  width: 200px;
  @media (max-width: 620px) {
      width: 150px;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  color: #9b44ff;
  gap: 10px;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
  width: fit-content;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.3s ease;
  }

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
    color:rgb(126, 30, 235);
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: left;
  }
`;
