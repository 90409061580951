import React from "react";
const BlackBall = ({ size }) => {
  return (
    <svg
      width={size ?? "70"}
      height={size ?? "70"}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="url(#paint0_radial_226_3523)" />
      <defs>
        <radialGradient
          id="paint0_radial_226_3523"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23 23) rotate(90) scale(23)"
        >
          <stop offset="0.045" stopColor="#F7F7F7" />
          <stop offset="0.44" stopColor="#4B4B4A" />
          <stop offset="1" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default BlackBall;
