import React from "react";

const EyeIcon = ({juego}) => {
  return (
    <svg
      width="37"
      height="25"
      viewBox="0 0 37 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3333 0C10 0 2.88333 5.18333 0 12.5C2.88333 19.8167 10 25 18.3333 25C26.6667 25 33.7833 19.8167 36.6667 12.5C33.7833 5.18333 26.6667 0 18.3333 0ZM18.3333 20.8333C13.7333 20.8333 10 17.1 10 12.5C10 7.9 13.7333 4.16667 18.3333 4.16667C22.9333 4.16667 26.6667 7.9 26.6667 12.5C26.6667 17.1 22.9333 20.8333 18.3333 20.8333ZM18.3333 7.5C15.5667 7.5 13.3333 9.73333 13.3333 12.5C13.3333 15.2667 15.5667 17.5 18.3333 17.5C21.1 17.5 23.3333 15.2667 23.3333 12.5C23.3333 9.73333 21.1 7.5 18.3333 7.5Z"
        fill={juego === "quini6" ? "#2AA9DA" : "#E8DF05"}
      />
    </svg>
  );
};

export default EyeIcon;
