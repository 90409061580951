import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import { estilosProv } from "../../configProvincias";
import MovimientosIcon from "../../img/movimientosIcon";
import { checkOrgAndNotLoggedIn } from "../../utils/generalUtils";
import InstallAppSalta from "../salta/InstallAppSalta";

const BottomNavMobile = (props) => {
  const fondoSpan =
    estilosProv[process.env.REACT_APP_COD_ORGANIZACION].secundaryColor;

  const [visible, setvisible] = useState(false);
  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
    props.volverAPlataforma();
  };

  useEffect(() => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        setvisible(!visible);
      }
    }
  }, []);

  const isOrgAndNotLoggedIn = checkOrgAndNotLoggedIn(
    ["53", "23"],
    props.logueado
  );

  const checkNotRequireUser = () => {
    if (isOrgAndNotLoggedIn) return true;

    return props.logueado;
  };

  const getItemContent = (item) => {
    let items = {
      movements: (
        <div>
          <MovimientosIcon
            color={
              window.location.href.includes("consultaBilletera")
                ? "#464646"
                : fondoSpan
            }
            className="billetera-icon"
            width="45px"
            height="45px"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Movimientos"
          />
        </div>
      ),
      myAccount: (
        <span
          className={
            "iconify user-icon " + process.env.REACT_APP_COD_ORGANIZACION
          }
          data-icon="bi:person"
          data-inline="false"
          data-flip="horizontal"
          data-width="35px"
          data-height="35px"
          color={fondoSpan}
        ></span>
      ),
    };
    return items[item];
  };

  const commonIconProps = {
    "data-inline": false,
    "data-width": "25px",
    color: [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
      ? "black"
      : "white",
  };

  return !(
    props.height < 500 &&
    ["portrait-primary", "portrait-secondary"].includes(
      window.screen.orientation.type
    )
  ) ? (
    <DivMenuRapido>
      <BotonVerMenu
        top="-2.2rem"
        type="button"
        className="btn btn-light"
        hidden={visible}
        onClick={() => setvisible(!visible)}
      >
        <span
          className="iconify help-arrow"
          data-icon="dashicons:arrow-down-alt2"
          {...commonIconProps}
        ></span>
      </BotonVerMenu>
      <BotonVerMenu
        top="-2.5rem"
        type="button"
        className="btn btn-light"
        hidden={!visible}
        onClick={() => setvisible(!visible)}
      >
        <span
          className="iconify help-arrow"
          data-icon="dashicons:arrow-up-alt2"
          {...commonIconProps}
        ></span>
      </BotonVerMenu>
      <DivMenuRapidoChild hidden={visible}>
        {checkNotRequireUser() && (
          <div
            className={"row mx-auto p-1 text-center"}
            style={{
              width: "100%",
              backgroundColor: "white",
              alignItems: "center",
            }}
          >
            {" "}
            <Link
              className={"my-icon col sm-3 pr-0 pl-0 inicioIphon "}
              exact={"true"}
              activeclassname="is-active"
              to="/home"
            >
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify home-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="bytesize:home"
                  data-inline="false"
                  data-width="35px"
                  data-height="35px"
                  color={fondoSpan}
                ></span>
              </div>
            </Link>
            {props.logueado ? (
              <Link
                to="/consultaBilletera"
                onClick={() => {
                  testAnim();
                }}
                className="col pr-0 pl-0 sm-3 billeIphon"
              >
                {getItemContent("movements")}
              </Link>
            ) : (
              <div
                className="col pr-0 pl-0 sm-3 billeIphon"
                data-toggle="modal"
                data-target={"#loginModal"}
                style={{ cursor: "pointer" }}
              >
                {getItemContent("movements")}
              </div>
            )}
            <div
              className="ripple"
              data-toggle="modal"
              data-target="#newGameModal"
              hidden={props.estado !== "PLATAFORMA"}
              style={{ zIndex: "3", padding: "0 1em", minWidth: "3em" }}
            >
              <div
                className={
                  "jugar-icon-cont bg-prov-" +
                  process.env.REACT_APP_COD_ORGANIZACION
                }
              >
                <span
                  style={{ marginTop: "5px", cursor: "pointer" }}
                  className="iconify"
                  data-icon="fluent:lottery-24-regular"
                  data-inline="false"
                  data-width="40px"
                  color={
                    [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
                      ? "black"
                      : "white"
                  }
                ></span>
              </div>
            </div>
            <Link
              className="my-icon col pr-0 pl-0 sm-3"
              to={
                process.env.REACT_APP_ENABLE_INGRESO_DINERO
                  ? props.estadoPlataforma?.apellido === "Toreto12"
                    ? "/identificadorQRCliente/0"
                    : "/identificadorQRCliente/1"
                  : "/identificadorQRCliente/"
              }
            >
              <div
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify retirar-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="ion:wallet-outline"
                  data-inline="false"
                  data-flip="horizontal"
                  data-width="35px"
                  data-height="35px"
                  color={fondoSpan}
                ></span>
              </div>
            </Link>
            {props.logueado ? (
              <Link
                className="my-icon col pr-0 pl-0 sm-3 userIphon5"
                to="/misDatosPersonales"
              >
                <div
                  onClick={() => {
                    testAnim();
                  }}
                >
                  {getItemContent("myAccount")}
                </div>{" "}
              </Link>
            ) : (
              <div
                className="my-icon col pr-0 pl-0 sm-3 userIphon5"
                data-toggle="modal"
                data-target={"#loginModal"}
                style={{ cursor: "pointer" }}
              >
                {getItemContent("myAccount")}
              </div>
            )}
          </div>
        )}
        {["53", "23"].includes(process.env.REACT_APP_COD_ORGANIZACION) &&
          props.size <= 820 &&
          props.instalable && (
            <InstallAppSalta
              installApp={props.installApp}
              updateInstallButton={props.updateInstallButton}
            />
          )}
      </DivMenuRapidoChild>
    </DivMenuRapido>
  ) : (
    <></>
  );
};

export default BottomNavMobile;

const BotonVerMenu = styled.button`
  top: ${(props) => props.top};
  position: absolute;
  z-index: 1;
  background-color: rgb(227, 229, 231);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  left: 0;
`;

const DivMenuRapido = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  box-shadow: 0px 0 10px rgb(146 146 146 / 80%);
  @media (min-width: 1920px) {
    // height: 50vh;
  }
`;

const DivMenuRapidoChild = styled.div`
  box-shadow: 0px 0 10px rgb(146 146 146 / 80%);
  @media (min-width: 1920px) {
  }
`;
