import React from "react";
import styled from "styled-components";
import StarIcon from "../../img/ganador.svg";
const Ganador = () => {
  return (
    <CuponGanador>
      <img src={StarIcon} alt="" />
      <TituloCuponGanador>CUPÓN GANADOR</TituloCuponGanador>
      <img src={StarIcon} alt="" />
    </CuponGanador>
  );
};

export default Ganador;
const CuponGanador = styled.div`
  background: #08e12b;
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
  border-radius: 20px;

  @media (max-width: 425px) {
    width: 98%;
  }
`;

const TituloCuponGanador = styled.h5`
  margin: 0;
  padding: 0 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;

  @media (max-width: 425px) {
    padding: 0 0.5rem;
  }
`;
