import React, { useEffect, useState } from "react";
import { estilosProv } from "../../configProvincias";
import { Form } from "reactstrap";
import InputSalta, { InputSelectSalta } from "./InputSalta";
import ButtonSalta from "./ButtonSalta";
import ModalTerminosCondiciones from "../../registrarse/tools/ModalTerminosCondiciones";
import RegistroSatisfactorio from "../../registrarse/RegistroSatisfactorio";
import styled from "styled-components";
import $ from "jquery";

const RegisterSalta = ({
  volverHOME,
  logoHeader,
  agencia,
  tycId,
  closeSession,
  cuil,
  setCuil,
  correo,
  setCorreo,
  telefono,
  setTelefono,
  localidad,
  setLocalidad,
  localidades,
  clave1,
  setClave1,
  clave2,
  setClave2,
  fetching,
  TerminosCondicionesTemplate,
  tyc,
  mensajeErrorWS,
  mensajeErrorGeneral,
}) => {
  const codOrg = process.env.REACT_APP_COD_ORGANIZACION;
  const fondo = estilosProv[codOrg].fondo;
  const [registrationDone, setRegistrationDone] = useState(false);
  const [legalAge, setLegalAge] = useState(false);
  const [tycAccepted, setTycAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstSubmitted, setFirstSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    cuil: "",
    correo: "",
    telefono: "",
    localidad: "",
    clave1: "",
    clave2: "",
    legalAge: "",
    tycAccepted: "",
  });

  useEffect(() => {
    closeSession();

    let allErrors = { ...errors };
    allErrors = handleCheckErrors("cuil", cuil, allErrors);
    allErrors = handleCheckErrors("correo", correo, allErrors);
    allErrors = handleCheckErrors("telefono", telefono, allErrors);
    allErrors = handleCheckErrors("localidad", localidad, allErrors);
    allErrors = handleCheckErrors("clave1", clave1, allErrors);
    allErrors = handleCheckErrors("clave2", clave2, allErrors);
    allErrors = handleCheckErrors("legalAge", legalAge, allErrors);
    allErrors = handleCheckErrors("tycAccepted", tycAccepted, allErrors);
    setErrors({ ...errors, ...allErrors });
  }, []);

  const handleCheckErrors = (fieldId, value, updateOwnErrorObj = undefined) => {
    let finalValue = "";
    const allErrors = updateOwnErrorObj
      ? { ...updateOwnErrorObj }
      : { ...errors };

    if (fieldId === "cuil") {
      value.length !== 13 && (finalValue = "El CUIL debe tener 11 dígitos");
      !value && (finalValue = "El CUIL es requerido");
      allErrors.cuil = finalValue;
    }

    if (fieldId === "correo") {
      value &&
        !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) &&
        (finalValue = "El correo debe ser un correo electrónico válido");
      !value && (finalValue = "El correo es requerido");
      allErrors.correo = finalValue;
    }

    // if (fieldId === "telefono") {
    //   const telefonoValue = String(value);
    //   !telefonoValue && (finalValue = "El teléfono es requerido");
    //   allErrors.telefono = finalValue;
    // }

    if (fieldId === "localidad") {
      !value && (finalValue = "La localidad es requerida");
      allErrors.localidad = finalValue;
    }

    if (fieldId === "clave1") {
      !/[A-Z]/.test(value) &&
        (finalValue = "La clave debe contener al menos una letra mayúscula");
      !/[a-z]/.test(value) &&
        (finalValue = "La clave debe contener al menos una letra minúscula");
      !/\d/.test(value) &&
        (finalValue = "La clave debe contener al menos un número");
      value.length < 8 &&
        (finalValue = "La clave debe tener al menos 8 caracteres");
      !value && (finalValue = "La clave es requerida");
      allErrors.clave1 = finalValue;

      if (clave2 && value !== clave2) {
        allErrors.clave2 = "Las claves no coinciden";
      } else {
        allErrors.clave2 = "";
      }
    }

    if (fieldId === "clave2") {
      value !== clave1 && (finalValue = "Las claves no coinciden");
      !value && (finalValue = "La confirmación de la clave es requerida");
      allErrors.clave2 = finalValue;
    }

    if (fieldId === "legalAge") {
      !value && (finalValue = "Debes ser mayor de 18 años");
      allErrors.legalAge = finalValue;
    }

    if (fieldId === "tycAccepted") {
      !value && (finalValue = "Debes aceptar los términos y condiciones");
      allErrors.tycAccepted = finalValue;
    }

    !updateOwnErrorObj && setErrors({ ...errors, ...allErrors });
    if (updateOwnErrorObj) return { ...allErrors };
  };

  const hasErrors = Object.values(errors).some((error) => error !== "");

  const handleSubmit = (e) => {
    e.preventDefault();

    setFirstSubmitted(true);

    if (hasErrors) return;

    let body = {
      codigoOrganizacion: process.env.REACT_APP_COD_ORGANIZACION_FETCH,
      cuil: cuil,
      documento: cuil.split("-")[1] || "",
      localidadId: localidad,
      email: correo,
      nroTelefono: telefono,
      password: clave1,
      matchingPassword: clave2,
      tipoDocumento: "DNI",
      agencia: agencia,
      mayorDeEdad: legalAge,
      tycId: tycId ?? "",
      recibeMsjEmail: true,
    };

    setLoading(true);
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_REGISTRACION;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(body),
    })
      .then((respPromise) => {
        return respPromise.json();
      })
      .then((json) => {
        setLoading(false);
        if (json.status === "ok") {
          setRegistrationDone(true);
        } else if (json.status === "error") {
          mensajeErrorWS("Error registración", json.errores);
          setRegistrationDone(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setRegistrationDone(false);
        mensajeErrorGeneral(error.message);
      });
  };

  return (
    <div className="wrapper pageFlow-reg">
      <div
        className="form-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          className={
            "encabezadoRegistracionWeb bg-prov-" +
            process.env.REACT_APP_COD_ORGANIZACION
          }
          style={{
            backgroundColor: fondo,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <div
              style={{ cursor: "pointer", color: "white" }}
              onClick={volverHOME}
            >
              <span
                className="iconify"
                data-icon="fe:arrow-left"
                data-inline="false"
                data-width="25px"
              ></span>
            </div>

            <img
              src={logoHeader}
              className="d-none d-sm-block"
              style={{ width: "160px" }}
            />
            <img
              src={logoHeader}
              className="d-block d-sm-none"
              style={{ width: "100px" }}
            />
          </div>

          <h5 className="m-bold" style={{ color: "white", margin: 0 }}>
            REGISTRO
          </h5>
        </div>

        {registrationDone && <RegistroSatisfactorio correo={correo} />}

        {!registrationDone && (
          <div
            style={{
              margin: "70px 0 0 0",
              maxWidth: "1080px",
              padding: "0 20px",
            }}
          >
            <Form
              autoComplete="off"
              id="registrarse"
              onSubmit={handleSubmit}
              noValidate
              className="row"
            >
              <div
                style={{ textAlign: "center", margin: "40px 0" }}
                className="col-12"
              >
                <h3 className="d-none d-md-block m-bold">
                  Ingresá tus datos para poder jugar al instante
                </h3>
                <h5
                  className="d-block d-md-none m-bold"
                  style={{ fontSize: "1.1rem" }}
                >
                  ¡Ingresá tus datos y jugá al instante!
                </h5>
              </div>

              <div className=" col-12 col-md-6 col-lg-4">
                <InputSalta
                  id={"cuil"}
                  label={"CUIL"}
                  placeholder={"27-43783735-6"}
                  required
                  value={cuil}
                  setValue={setCuil}
                  isCuil
                  errorMessage={firstSubmitted ? errors.cuil : ""}
                  handleCheckErrors={handleCheckErrors}
                  helperText="Consultá tu CUIL online"
                  helperTextAction={() => {
                    window.open(
                      "https://www.anses.gob.ar/consulta/constancia-de-cuil",
                      "_blank"
                    );
                  }}
                  // extraInfo={
                  //   <button
                  //     type="button"
                  //     style={{
                  //       outline: "none",
                  //       border: "none",
                  //       background: "transparent",
                  //       top: "-1px",
                  //       position: "relative",
                  //       pointerEvents: "all",
                  //       marginLeft: "3px",
                  //     }}
                  //     onClick={() => {
                  //       $("#cuilHelpModal").modal("show");
                  //     }}
                  //   >
                  //     <span
                  //       className="iconify"
                  //       data-icon="material-symbols:info-outline"
                  //       data-inline="false"
                  //       data-width="17px"
                  //     ></span>
                  //   </button>
                  // }
                />
              </div>

              <div className=" col-12 col-md-6 col-lg-4">
                <InputSalta
                  id={"correo"}
                  label={"Correo electrónico"}
                  placeholder={"correo@example.com.ar"}
                  required
                  value={correo}
                  setValue={setCorreo}
                  errorMessage={firstSubmitted ? errors.correo : ""}
                  handleCheckErrors={handleCheckErrors}
                />
              </div>

              <div className=" col-12 col-md-6 col-lg-4">
                <InputSalta
                  id={"telefono"}
                  label={"Teléfono móvil"}
                  placeholder={"3874452874"}
                  value={telefono}
                  setValue={setTelefono}
                  type={"number"}
                  errorMessage={firstSubmitted ? errors.telefono : ""}
                  handleCheckErrors={handleCheckErrors}
                />
              </div>

              <div className="col-12 my-4 py-2 d-none d-lg-block"></div>

              <div className=" col-12 col-md-6 col-lg-4">
                <InputSelectSalta
                  id={"localidad"}
                  label={"Localidad"}
                  required
                  value={localidad}
                  setValue={setLocalidad}
                  options={localidades.map((item) => ({
                    value: item.id,
                    label: item.nombre,
                  }))}
                  errorMessage={firstSubmitted ? errors.localidad : ""}
                  handleCheckErrors={handleCheckErrors}
                />
              </div>

              <div className=" col-12 col-md-6 col-lg-4">
                <InputSalta
                  id={"clave1"}
                  label={"Contraseña"}
                  placeholder={"xxxxxxxxxxxx"}
                  required
                  value={clave1}
                  setValue={setClave1}
                  type={"password"}
                  helperText={
                    "Al menos 8 caracteres, una mayúscula, una minúscula y un número."
                  }
                  errorMessage={firstSubmitted ? errors.clave1 : ""}
                  handleCheckErrors={handleCheckErrors}
                />
              </div>

              <div className=" col-12 col-md-6 col-lg-4">
                <InputSalta
                  id={"clave2"}
                  label={"Confirmar contraseña"}
                  placeholder={"xxxxxxxxxxxx"}
                  required
                  value={clave2}
                  setValue={setClave2}
                  type={"password"}
                  errorMessage={firstSubmitted ? errors.clave2 : ""}
                  handleCheckErrors={handleCheckErrors}
                  helperText={
                    "Al menos 8 caracteres, una mayúscula, una minúscula y un número."
                  }
                />
              </div>

              <div className="col-12 col-md-6 col-lg-4 d-none d-lg-block"></div>
              <div className="col-12 col-lg-4 mt-2 mt-lg-5">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    value={legalAge}
                    onChange={(e) => {
                      handleCheckErrors("legalAge", e.target.checked);
                      setLegalAge(e.target.checked);
                    }}
                    id="legalAge"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="legalAge"
                    style={{ fontSize: ".95rem" }}
                  >
                    Soy mayor de 18 años
                  </label>
                  {firstSubmitted && errors.legalAge && (
                    <ErrorMsgCustom>{"Debes marcar este campo"}</ErrorMsgCustom>
                  )}
                </div>

                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    style={{ cursor: "pointer" }}
                    value={tycAccepted}
                    onChange={(e) => {
                      handleCheckErrors("tycAccepted", e.target.checked);
                      setTycAccepted(e.target.checked);
                      e.target.checked && $("#terminosModal").modal("show");
                    }}
                    id="tycAccepted"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="tycAccepted"
                    style={{ fontSize: ".95rem" }}
                  >
                    Acepto términos y condiciones -
                  </label>
                  <p
                    style={{
                      color: "#00C975",
                      cursor: "pointer",
                      margin: 0,
                      display: "inline",
                      fontSize: ".95rem",
                    }}
                    className="m-bold"
                    data-toggle="modal"
                    data-target="#terminosModal"
                  >
                    {" "}
                    VER{" "}
                  </p>
                  {firstSubmitted && errors.tycAccepted && (
                    <ErrorMsgCustom>{"Debes marcar este campo"}</ErrorMsgCustom>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-none d-lg-block"></div>

              <div
                class="col-12 mt-5"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ButtonSalta
                  variant="danger"
                  disabled={loading}
                  type="button"
                  onClick={volverHOME}
                >
                  Volver
                </ButtonSalta>
                <ButtonSalta variant="primary" disabled={loading} type="submit">
                  {loading ? (
                    <div className="animate-flicker">
                      <b>Cargando</b>
                    </div>
                  ) : (
                    "Registrarse"
                  )}
                </ButtonSalta>
              </div>
            </Form>

            <ModalTerminosCondiciones
              fetching={fetching}
              tyc={tyc}
              TerminosCondicionesTemplate={TerminosCondicionesTemplate}
            />

            {/* modal cuil help */}
            {/* <div
              className="modal fade"
              style={{
                position: "fixed",
                top: "0",
                left: "0",
                width: "100vw",
                height: "100vh",
                backgroundColor: "rgba(0,0,0,0.5)",
                zIndex: 9,
              }}
              id="cuilHelpModal"
              role="dialog"
              aria-labelledby="cuilHelpModal"
              aria-hidden="true"
            >
              <DivModal className="modal-dialog" role="document">
                <div className="modal-content" style={{ borderRadius: "15px" }}>
                  <div
                    className="modal-body text-center text-small"
                    style={{
                      background: "#ffffff",
                      borderRadius: "15px",
                      padding: "15px",
                    }}
                  >
                    <img src={cuilHelpImg} alt="CUIL AYUDA" width={"100%"} />
                  </div>
                </div>
              </DivModal>
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisterSalta;

const ErrorMsgCustom = styled.p`
  color: rgb(218, 3, 3);
  font-size: 0.8rem;
  margin: 5px 0;
`;

const DivModal = styled.div`
  box-shadow: none !important;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
