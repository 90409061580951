import React, { useState } from "react";
import $ from "jquery";
import styled from "styled-components";
import { useEffect } from "react";

const ModalJuegoResponsable = ({ userToken, cerrarSesion, logueado }) => {
  const [titulo, settitulo] = useState("");
  const [jugadoJornada, setjugadoJornada] = useState("");
  const [jugadoSesion, setjugadoSesion] = useState("");
  const [balanceJornada, setbalanceJornada] = useState("");
  const [balanceSesion, setbalanceSesion] = useState("");
  const [premiosJornada, setpremiosJornada] = useState("");
  const [premiosSesion, setpremiosSesion] = useState("");
  const [horasSesion, sethorasSesion] = useState("");
  const [minutosSesion, setminutosSesion] = useState("");
  const [minutosNotif, setminutosNotif] = useState(undefined);
  const [interval, setinterval] = useState(undefined);
  useEffect(() => {
    traerDatosJuegoResponsable();
  }, []);

  const ejecutarInterval = (min) => {
    let intervalo = min * 60000;
    $(document).ready(function () {
      setinterval(
        setInterval(function () {
          $("#trigger").click();
        }, intervalo)
      );
    });
  };

  const traerDatosJuegoResponsable = () => {
    if (
      ["53", "23"].includes(process.env.REACT_APP_COD_ORGANIZACION) &&
      !logueado
    )
      return;

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_DATOS_JUEGO_RESPONSABLE;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          this.props.cerrarSesion();
          this.props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          settitulo(json.titulo);
          setjugadoJornada(json.jugadoJornada);
          setjugadoSesion(json.jugadoSesion);
          setbalanceJornada(json.balanceJornada);
          setbalanceSesion(json.balanceSesion);
          setpremiosJornada(json.premiosJornada);
          setpremiosSesion(json.premiosSesion);
          sethorasSesion(json.horasSesion);
          setminutosSesion(json.minutosSesion);
          setminutosNotif(json.minutosNotif);
          return json.minutosNotif;
        } else if (json.status === "error") {
          this.props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
      })
      .then((min) => {
        if (minutosNotif === undefined) {
          ejecutarInterval(min);
        } else {
          clearInterval(interval);
          ejecutarInterval(min);
        }
      })
      .catch((error) => {});
  };

  const handleClick = () => {
    traerDatosJuegoResponsable();
  };
  return (
    <div>
      {/* Button trigger modal */}
      <button
        hidden={true}
        id="trigger"
        type="button"
        className="btn btn-primary"
        data-toggle="modal"
        data-target="#modalExample"
        onClick={handleClick}
      ></button>
      <div
        style={{ position: "fixed", top: "6%" }}
        className="modal fade"
        id="modalExample"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content pb-2">
            <div className="modal-header">
              <h5
                className="modal-title text-center"
                id="juegoResponsableLabel"
              >
                {titulo}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body pt-0" style={{ maxHeight: "60vh" }}>
              <div
                className="modal-body text-center mb-2 pb-5"
                style={{ overflowY: "scroll" }}
              >
                <h4 className="m-bold mb-3 bg-dark text-light p-2 rounded">
                  Últimas 24 horas
                </h4>
                <div className="row mb-3">
                  <div className="col sm-3">
                    <div className="m-bold"> Premios </div>
                    <div style={{ fontSize: "23px" }}>
                      <div>&#36;{premiosJornada}</div>
                    </div>
                  </div>
                  <div className="col sm-3 mb-2">
                    <div className="m-bold">Jugado</div>
                    <div style={{ fontSize: "23px" }}>
                      <div>&#36; {jugadoJornada}</div>
                    </div>
                  </div>
                  <div className="col sm-3 mb-2">
                    <div className="m-bold">Balance</div>
                    <div style={{ fontSize: "23px" }}>
                      <div>&#36;{balanceJornada}</div>
                    </div>
                  </div>
                </div>
                <hr className="mb-4" />
                <h4 className="m-bold mt-3 mb-3 bg-dark text-light p-2 rounded">
                  Datos de la sesión
                </h4>
                <p>
                  Usted lleva{" "}
                  <span className="m-bold text-danger">
                    {horasSesion}hrs {minutosSesion}min
                  </span>{" "}
                  jugando
                </p>
                <div className="row mt-4">
                  <div className="col sm-3 mb-2">
                    <div className="m-bold">Premios</div>
                    <div style={{ fontSize: "23px" }}>
                      <div>&#36;{premiosSesion}</div>
                    </div>
                  </div>

                  <div className="col sm-3 mb-2">
                    <div className="m-bold">Jugado</div>
                    <div style={{ fontSize: "23px" }}>
                      <div>&#36;{jugadoSesion}</div>
                    </div>
                  </div>

                  <div className="col sm-3 mb-2">
                    <div className="m-bold">Balance</div>
                    <div style={{ fontSize: "23px" }}>
                      <div>&#36;{balanceSesion}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-footer bg-light"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <BotonCerrarSesion
                onClick={cerrarSesion}
                className="btn btn-danger p-2 pl-3 pr-3"
              >
                CERRAR SESIÓN
              </BotonCerrarSesion>
              <button
                className={
                  "btn btn-success p-2 pl-3 pr-3 bg-btn-extraccion-prov-" +
                  process.env.REACT_APP_COD_ORGANIZACION
                }
                data-dismiss="modal"
                aria-label="Close"
              >
                CONTINUAR
              </button>
              <br />
              {["43", "13"].includes(process.env.REACT_APP_COD_ORGANIZACION) ? (
                <a
                  className="text-info font-weight-bold"
                  href="https://www.loteriaderionegro.gob.ar/?contID=59698"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  Ir a cuestionario de autoevaluación
                </a>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalJuegoResponsable;

const BotonCerrarSesion = styled.a`
  color: white !important;
`;
