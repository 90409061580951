import React from "react";
import { Animated } from "react-animated-css";
import { checkOrg } from "../utils/generalUtils";

const RegistroSatisfactorio = ({ correo }) => {
  const isOrg = checkOrg(["53", "23"]);

  return (
    <Animated
      animationIn="fadeIn"
      style={{ marginTop: "60%" }}
      isVisible={true}
    >
      <div className="mx-auto text-center " id="exitoRegistroDesk">
        <Animated
          animationIn="bounceIn"
          animationInDelay={800}
          animationInDuration={1000}
        >
          <span
            className="iconify text-success"
            data-icon="el:ok-circle"
            data-inline="false"
            data-width="100px"
          ></span>
        </Animated>

        <h4 className="mt-4 mb-4">Tu registro se cargó satisfactoriamente</h4>

        <hr
          className="mx-auto w-75 textFootDesk2"
          style={{ marginTop: "25%" }}
        />

        <div className="p-2 mt-1 font-12 op-text textFootDesk">
          <p>
            Ingresá a tu correo dentro de las 24 hs. <b>{correo}</b> para
            activar tu cuenta.
          </p>
          {!isOrg && (
            <p>Acércate a una agencia oficial, carga crédito y gana.</p>
          )}
        </div>
      </div>
    </Animated>
  );
};

export default RegistroSatisfactorio;
