import React from "react";
import styled from "styled-components";

const FullModal = (props) => {
  return (
    <MenuContainer>
      <Menu open={props.open}>
        <Button type="button" aria-label="Close" onClick={props.handleClose}>
          <span
            className="iconify"
            data-icon="carbon:close-outline"
            data-inline="false"
            data-width={"30px"}
          ></span>
        </Button>
        <Content>{props.children}</Content>
      </Menu>
    </MenuContainer>
  );
};

export default FullModal;

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  pointer-events: none;
  z-index: 100;
`;

const Menu = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: #9b44ff;
  transform: ${(props) => (props.open ? "translateY(0)" : "translateY(-100%)")};
  transition: transform 0.5s ease-in-out;
  pointer-events: ${(props) => (props.open ? "auto" : "none")};
  color: #fff;
  padding: 20px;
  overflow-y: auto;
`;

const Button = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #ffffff;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Content = styled.div`
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
