import React from "react";
import ErrorIcon from "../../img/icons/error.png";
import SuccessIcon from "../../img/icons/success.png";
import "./Alert.css";
const Alert = ({ alert }) => {
  return (
    <div className="containerAlert">
      <div className="alert">
        {alert.codigo === 200 && (
          <>
            <img src={SuccessIcon} height={100} width={100} alt="" />
            <h3 className="alertTitle alertTitleSuccess">Completado</h3>
            <p className="alertDetail">{alert.mensaje}</p>
          </>
        )}
        {alert.codigo !== 200 && (
          <>
            <img src={ErrorIcon} height={100} width={100} alt="" />
            <h3 className="alertTitle alertTitleError">ERROR</h3>
            <p className="alertDetail">{alert.error}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default Alert;
