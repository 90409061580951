import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { estilosProv } from "../../configProvincias";
import { estilosProvNew } from "../../configProvinciasNew";
import NumberFormat from "react-number-format";
import $ from "jquery";

const codOrg = process.env.REACT_APP_COD_ORGANIZACION;
const LlamadorJuego = ({
  codigoJuego,
  enableJuego,
  clickJuego,
  cantCuponesJuego,
  pozoEstimado,
  logoJuego,
  nombreJuego,
  promocion,
  estadoPlataforma,
  urljuego,
  propSide,
  tieneCuil,
  size,
  isNavBar,
}) => {
  const [stateTooltip, setStateTooltip] = useState(false);
  const fondoLlamador = estilosProv[codOrg].fondoLlamador;
  const fondo = estilosProv[codOrg].fondo;
  const fondoCard = estilosProvNew[codOrg].color;
  const gradiente1Llamador = estilosProv[codOrg].gradiente1Llamador;
  const gradiente2Llamador = estilosProv[codOrg].gradiente2Llamador;

  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
  };

  const linkAJuego = () => {
    if (
      ["quinielainstantanea", "quiniexpress", "tomboexpress"].includes(
        urljuego.toLowerCase()
      )
    ) {
      return "/juego/" + urljuego;
    }
    if (
      [
        "patagoniaminibingo",
        "patagoniatelebingo",
        "tombola",
        "quinielatradicional",
        "pozodelaquiniela",
        "loteriaunificada",
        "raspadita_money",
        "quini6",
        "lotoplus",
      ].includes(urljuego.toLowerCase())
    ) {
      return "/juego/" + urljuego;
    }
  };

  const showTooltip = () => {
    setStateTooltip(true);
  };
  const hideTooltip = () => {
    setStateTooltip(false);
  };

  return (
    enableJuego === "true" &&
    (estadoPlataforma.acceptedTyc === true ||
      estadoPlataforma.acceptedTyc === null ||
      estadoPlataforma.acceptedTyc === undefined) &&
    (estadoPlataforma.autoexcluidoHasta === undefined ||
      estadoPlataforma.autoexcluidoHasta === null) &&
    (isNavBar === false ? (
      <DivIconoLlamadorJuego>
        <Animated animationIn="flipInY" animationInDuration={1200}>
          <ContainerJuegos
            onClick={() => {
              testAnim();
              window.generarEvento(
                nombreJuego + "Selección",
                "Ir a juego " + nombreJuego,
                ""
              );
              if (urljuego === "patagoniaminibingo") {
                clickJuego("20");
              } else if (urljuego === "patagoniatelebingo") {
                clickJuego("21");
              } else if (urljuego === "quini6") {
                clickJuego("4");
              } else if (urljuego === "lotoplus") {
                clickJuego("3");
              } else {
                clickJuego();
              }
              let b = document.getElementById("newGameModalDesktop");
              if (b !== null) {
                if (b.className.includes("modal fade show")) {
                  $("#BtnNewGameDesktop123").click();
                }
              }
            }}
          >
            <Link to={linkAJuego}>
              <div className="row justify-content-center text-center">
                {["23", "53"].includes(codOrg) ? (
                  <>
                    <ContainerCard
                      colorFrom={
                        typeof codigoJuego === "number"
                          ? fondoCard[codigoJuego].colorFrom
                          : fondoCard["raspadita"].colorFrom
                      }
                      colorTo={
                        codigoJuego
                          ? fondoCard[codigoJuego].colorTo
                          : fondoCard["raspadita"].colorTo
                      }
                    >
                      {cantCuponesJuego > 0 && (
                        <BadgeNotificacionesJuego
                          codOrg={codOrg}
                          onMouseOver={() => showTooltip()}
                          onMouseLeave={() => hideTooltip()}
                          className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle"
                        >
                          {cantCuponesJuego}
                        </BadgeNotificacionesJuego>
                      )}
                      {stateTooltip && <ToolTip>Cupones pendientes</ToolTip>}

                      {pozoEstimado && (
                        <Animated
                          animationIn="fadeInRight"
                          animationInDuration={1200}
                        >
                          {/* {stateTooltip && (<ToolTip>Cupones pendientes</ToolTip>)} */}
                          <BadgePozoEstimadoJuego className="position-absolute top-0 right-0 start-100 translate-middle border-none rounded-none border-light py-1">
                            <p
                              className="p-0 m-0"
                              style={{ lineHeight: "16px" }}
                            >
                              POZO
                            </p>
                            <p
                              className="p-0 m-0"
                              style={{ lineHeight: "20px" }}
                            >
                              <NumberFormat
                                value={Number(pozoEstimado.split(" ")[0])}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={false}
                                isNumericString={true}
                              />{" "}
                              {pozoEstimado.split(" ")[1]}
                            </p>
                          </BadgePozoEstimadoJuego>
                        </Animated>
                      )}
                      <FondoLogoCard>
                        <LogoJuegoCard src={logoJuego} alt="img" />
                      </FondoLogoCard>
                      <BottomCard>
                        <p className="m-0 p-0">{nombreJuego}</p>
                      </BottomCard>
                    </ContainerCard>
                  </>
                ) : (
                  <>
                    <FondoLogoBolilla
                      size={size}
                      aplicarGradiente={
                        ["18", "48"].includes(codOrg) ? false : true
                      }
                      gradiente1Llamador={
                        gradiente1Llamador !== ""
                          ? urljuego.toLowerCase() === "tombola"
                            ? ["18", "48"].includes(codOrg)
                              ? "#ffffff00"
                              : "#f58634"
                            : urljuego.toLowerCase() === "tomboexpress"
                            ? ["18", "48"].includes(codOrg)
                              ? "#ffffff00"
                              : "#ed3054"
                            : gradiente1Llamador
                          : ""
                      }
                      gradiente2Llamador={
                        gradiente2Llamador !== "" ? gradiente2Llamador : ""
                      }
                      colorFondo={
                        urljuego.toLowerCase() === "tombola"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#f58634"
                          : urljuego.toLowerCase() === "tomboexpress"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#ed3054"
                          : fondoLlamador
                      }
                      className="position-relative  align-self-center"
                    >
                      {cantCuponesJuego > 0 && (
                        <BadgeNotificacionesJuego
                          codOrg={codOrg}
                          onMouseOver={() => showTooltip()}
                          onMouseLeave={() => hideTooltip()}
                          className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle"
                        >
                          {cantCuponesJuego}
                        </BadgeNotificacionesJuego>
                      )}

                      {pozoEstimado && (
                        <Animated
                          animationIn="fadeInRight"
                          animationInDuration={1200}
                        >
                          {/* {stateTooltip && (<ToolTip>Cupones pendientes</ToolTip>)} */}
                          <BadgePozoEstimadoJuego className="position-absolute top-0 right-0 start-100 translate-middle border-none rounded-none border-light py-1">
                            <p
                              className="p-0 m-0"
                              style={{ lineHeight: "16px" }}
                            >
                              POZO
                            </p>
                            <p
                              className="p-0 m-0"
                              style={{ lineHeight: "20px" }}
                            >
                              <NumberFormat
                                value={Number(pozoEstimado.split(" ")[0])}
                                displayType={"text"}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                prefix={"$"}
                                decimalScale={2}
                                fixedDecimalScale={false}
                                isNumericString={true}
                              />{" "}
                              {pozoEstimado.split(" ")[1]}
                            </p>
                          </BadgePozoEstimadoJuego>
                        </Animated>
                      )}
                      <LogoJuego
                        size={size}
                        src={logoJuego}
                        sinFondo={["18", "48", "23", "53"].includes(codOrg)}
                        alt="img"
                      />
                    </FondoLogoBolilla>
                    {!["23", "53", "18", "48"].includes(codOrg) ? (
                      <div className="col-10">
                        <NombreJuego>{nombreJuego}</NombreJuego>
                      </div>
                    ) : (
                      ""
                    )}
                    <SpanPromociones size={size}>
                      {promocion !== undefined ? promocion : <></>}
                    </SpanPromociones>
                  </>
                )}
              </div>
            </Link>
          </ContainerJuegos>
        </Animated>
      </DivIconoLlamadorJuego>
    ) : (
      <DivIconoLlamadorJuegoNavBar className={propSide}>
        <Animated
          animationIn="flipInY"
          animationInDuration={1200}
          isVisible={true}
        >
          <ContainerJuegosNavBar
            className="container"
            onClick={() => {
              //console.log(urljuego);
              window.generarEvento(
                nombreJuego + "Selección",
                "Ir a juego " + urljuego,
                ""
              );
              testAnim();
              if (urljuego === "patagoniaminibingo") {
                clickJuego("20");
              } else if (urljuego === "patagoniatelebingo") {
                clickJuego("21");
              } else if (urljuego === "quini6") {
                clickJuego("4");
              } else if (urljuego === "lotoplus") {
                clickJuego("3");
              } else {
                clickJuego();
              }
              let b = document.getElementById("newGameModalDesktop");
              if (b !== null) {
                if (b.className.includes("modal fade show")) {
                  $("#BtnNewGameDesktop123").click();
                }
              }
            }}
          >
            <Link to={linkAJuego}>
              {["23", "53"].includes(codOrg) ? (
                <>
                  <ContainerCard
                    colorFrom={
                      typeof codigoJuego === "number"
                        ? fondoCard[codigoJuego].colorFrom
                        : fondoCard["raspadita"].colorFrom
                    }
                    colorTo={
                      codigoJuego
                        ? fondoCard[codigoJuego].colorTo
                        : fondoCard["raspadita"].colorTo
                    }
                    marginX="0px"
                    height="160px"
                  >
                    {cantCuponesJuego > 0 && (
                      <BadgeNotificacionesJuego
                        codOrg={codOrg}
                        onMouseOver={() => showTooltip()}
                        onMouseLeave={() => hideTooltip()}
                        className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle"
                      >
                        {cantCuponesJuego}
                      </BadgeNotificacionesJuego>
                    )}

                    {pozoEstimado && (
                      <Animated
                        animationIn="fadeInRight"
                        animationInDuration={1200}
                      >
                        {/* {stateTooltip && <ToolTip>Cupones pendientes</ToolTip>} */}
                        <BadgePozoEstimadoJuego className="position-absolute top-0 right-0 start-100 translate-middle border-none rounded-none border-light py-1">
                          <p className="p-0 m-0" style={{ lineHeight: "16px" }}>
                            POZO
                          </p>
                          <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                            <NumberFormat
                              value={Number(pozoEstimado.split(" ")[0])}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={false}
                              isNumericString={true}
                            />{" "}
                            {pozoEstimado.split(" ")[1]}
                          </p>
                        </BadgePozoEstimadoJuego>
                      </Animated>
                    )}
                    <FondoLogoCard height="100%">
                      <LogoJuegoCard
                        size={size}
                        src={logoJuego}
                        sinFondo={["18", "48", "23", "53"].includes(codOrg)}
                        alt="img"
                      />
                    </FondoLogoCard>
                    {/* <BottomCard>
                      <p className="m-0 p-0">{nombreJuego}</p>
                    </BottomCard> */}
                  </ContainerCard>
                </>
              ) : (
                <>
                  <FondoLogoNavBar
                    size={size}
                    aplicarGradiente={
                      !["48", "18"].includes(
                        process.env.REACT_APP_COD_ORGANIZACION
                      )
                    }
                    gradiente1Llamador={
                      gradiente1Llamador !== ""
                        ? urljuego.toLowerCase() === "tombola"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#f58634"
                          : urljuego.toLowerCase() === "tomboexpress"
                          ? ["18", "48"].includes(codOrg)
                            ? "#ffffff00"
                            : "#ed3054"
                          : gradiente1Llamador
                        : ""
                    }
                    gradiente2Llamador={
                      gradiente2Llamador !== "" ? gradiente2Llamador : ""
                    }
                    colorFondo={
                      urljuego.toLowerCase() === "tombola"
                        ? ["18", "48"].includes(codOrg)
                          ? "#ffffff00"
                          : "#f58634"
                        : urljuego.toLowerCase() === "tomboexpress"
                        ? ["18", "48"].includes(codOrg)
                          ? "#ffffff00"
                          : "#ed3054"
                        : fondoLlamador
                    }
                    className=""
                  >
                    {cantCuponesJuego > 0 && (
                      <BadgeNotificacionesJuego
                        codOrg={codOrg}
                        className="position-absolute top-0 start-100 translate-middle border border-light rounded-circle"
                      >
                        {cantCuponesJuego}
                      </BadgeNotificacionesJuego>
                    )}
                    {pozoEstimado && (
                      <Animated
                        animationIn="fadeInRight"
                        animationInDuration={1200}
                      >
                        <BadgePozoEstimadoJuego className="position-absolute top-0 right-0 start-100 translate-middle border-none rounded-none border-light py-1">
                          <p className="p-0 m-0" style={{ lineHeight: "16px" }}>
                            POZO
                          </p>
                          <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                            <NumberFormat
                              value={Number(pozoEstimado.split(" ")[0])}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={false}
                              isNumericString={true}
                            />{" "}
                            {pozoEstimado.split(" ")[1]}
                          </p>
                        </BadgePozoEstimadoJuego>
                      </Animated>
                    )}
                    <LogoJuegoNavBar size={size} src={logoJuego} alt="img" />
                  </FondoLogoNavBar>
                  <div className="9">
                    <NombreJuego>{nombreJuego}</NombreJuego>
                  </div>
                  <SpanPromociones size={size}>
                    {promocion !== undefined ? promocion : <></>}
                  </SpanPromociones>
                </>
              )}
            </Link>
          </ContainerJuegosNavBar>
        </Animated>
      </DivIconoLlamadorJuegoNavBar>
    ))
  );
};

export default LlamadorJuego;

const ContainerCard = styled.div`
  margin: 0 ${(props) => props.marginX ?? "45px"};
  height: ${(props) => props.height ?? "190px"};
  width: 155px;
  border-radius: 20px;
  background: ${(props) => props.colorFrom};
  background: linear-gradient(
    180deg,
    ${(props) => props.colorFrom} 0%,
    ${(props) => props.colorTo} 100%
  );
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  transition: 0.5s all;
  transform: scale(1);

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 425px) {
    height: 170px;
    width: 135px;
  }
`;

const FondoLogoCard = styled.div`
  height: ${(props) => props.height ?? "75%"};
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const LogoJuegoCard = styled.img`
  width: 120px;
  height: 50px;
  object-fit: contain;
  @media (max-width: 425px) {
    width: 100px;
    height: 45px;
  }
`;

const BottomCard = styled.div`
  background: #e2e2e2;
  height: 25%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoJuego = styled.img`
  width: ${(props) =>
    !props.sinFondo ? "60%" : props.size > 991 ? "25vh" : "18vh"};
  margin-left: ${(props) => (props.sinFondo ? "-2vh" : "0vh")};
`;

const checkOrg = (size) => {
  if (["48", "18"].includes(process.env.REACT_APP_COD_ORGANIZACION)) {
    return "14vh";
  }

  // rest of the organizations
  return size <= 820 ? "7vh" : "13vh";
};

const LogoJuegoNavBar = styled.img`
  width: ${(props) => checkOrg(props.size)};
  /* margin-top: ${(props) => (props.size <= 991 ? "2vh" : "4.5vh")}; */
`;

const BadgePozoEstimadoJuego = styled.span`
  background-color: #00c975;
  color: white;
  z-index: 3;
  width: 90%;
  // height: 6vh;
  text-align: center;
  left: 50%;
  top: -20px;
  align-content: center;
  font-size: 15px;
  border-radius: 5px;
  transform: translateX(-50%);
  font-weight: 600;
`;

const BadgeNotificacionesJuego = styled.span`
  background-color: #5a83bd;
  border: 2px solid white;
  border-radius: 50%;
  color: white;
  z-index: 3;
  width: 4vh;
  height: 4vh;
  text-align: center;
  left: ${(props) => (["23", "53"].includes(props.codOrg) ? "-20px" : "0px")};
  top: 0px;
  align-content: center;
  font-size: 2vh;
`;

const NombreJuego = styled.div`
  margin-top: 4vh;
  text-align: center;
  @media (min-width: 1920px) {
    font-size: x-large;
    font-weight: 900;
  }
`;

const rotate2 = keyframes`
0% {
    box-shadow: 8px -5px 13px 4px #b0b7b3;
  }
  50% {
    box-shadow: 8px -5px 20px 8px #b0b7b3;
  }
  100% {
    box-shadow: 8px -5px 13px 4px #b0b7b3;
  }
`;

const FondoLogoBolilla = styled.div`
  background: radial-gradient(
    circle at 65% 15%,
    ${(props) =>
        props.aplicarGradiente && props.gradiente1Llamador !== ""
          ? "white"
          : props.colorFondo}
      1px,
    ${(props) =>
        props.aplicarGradiente && props.gradiente1Llamador !== ""
          ? props.gradiente1Llamador
          : props.colorFondo}
      3%,
    ${(props) => (props.aplicarGradiente ? props.colorFondo : props.colorFondo)}
      60%,
    ${(props) =>
        props.aplicarGradiente && props.gradiente2Llamador !== ""
          ? props.gradiente2Llamador
          : props.colorFondo}
      100%
  );
  border-radius: 50%;
  align-content: center;
  height: ${(props) => (props.size <= 991 ? "15vh" : "20vh")};

  max-width: ${(props) => (props.size <= 991 ? "16vh" : "20vh")};
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px -5px 13px 4px #b0b7b3" : ""};
  width: 100%;
  &:hover {
    ${(props) =>
      props.aplicarGradiente &&
      "box-shadow: 0 0 15px 5px " + props.colorFondo + " !important;"}
    transform: scale(1.1);
  }
`;

const FondoLogoNavBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(
    circle at 65% 15%,
    ${(props) =>
        props.aplicarGradiente && props.gradiente1Llamador !== ""
          ? "white"
          : props.colorFondo}
      1px,
    ${(props) =>
        props.aplicarGradiente && props.gradiente1Llamador
          ? props.gradiente1Llamador
          : props.colorFondo}
      3%,
    ${(props) => (props.aplicarGradiente ? props.colorFondo : props.colorFondo)}
      60%,
    ${(props) =>
        props.aplicarGradiente && props.gradiente2Llamador !== ""
          ? props.gradiente2Llamador
          : props.colorFondo}
      100%
  );
  border-radius: 50%;
  height: ${(props) => (props.size <= 820 ? "13vh" : "20vh")};
  max-width: ${(props) => (props.size <= 820 ? "13vh" : "20vh")};
  width: ${(props) => (props.size <= 820 ? "13vh" : "100%")};
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px -5px 13px 4px #b0b7b3" : ""}
`;

const ContainerJuegos = styled.div`
  min-width: 20vh;
`;

const ContainerJuegosNavBar = styled.div`
  min-width: 13vh;
  margin-top: 4vh;
  margin-bottom: 5vh;
`;

const SpanPromociones = styled.span`
  background-color: #01c160;
  color: #f0f0f0;
  border-radius: 1em;
  position: absolute;
  top: ${(props) => (props.size <= 991 ? "13.5vh" : "17.5vh")};
  min-width: 8rem;
  text-align: center;
`;
const DivIconoLlamadorJuego = styled.div`
  min-width: 21vh;
  margin: 0 5px;
`;

const DivIconoLlamadorJuegoNavBar = styled.div`
  /* min-width: 14vh; */
`;

const ToolTip = styled.p`
  font-size: 12px;
  color: black;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: -30px;
  left: -15px;
  z-index: 99;
  background: #dedede;
`;
