import React from "react";
import { estilosProv } from "./configProvincias";
import { checkOrg } from "./utils/generalUtils";

const Contactos = () => {
  const provincia = estilosProv[process.env.REACT_APP_COD_ORGANIZACION];
  return (
    <div className="p-1 mx-auto">
      <h5 className="text-center mb-3 font-16">
        Podés contactarte con nosotros por los siguientes medios:
      </h5>
      {provincia.numeroWhatsapp !== undefined && (
        <div className="bg-dark text-light rounded text-center p-2 mb-4">
          <span
            className="iconify px-2"
            data-icon="logos:whatsapp"
            data-inline="false"
            data-height="20px"
            data-width="85px"
          ></span>

          {checkOrg(["23", "53"]) ? (
            <a
              href={"https://wa.me/+54" + provincia.numeroWhatsapp}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "white",
                textDecoration: "none",
                position: "relative",
                transition: "all 0.3s ease",
              }}
              onMouseEnter={(e) =>
                (e.target.style.color = "rgb(227, 227, 227)")
              }
              onMouseLeave={(e) => (e.target.style.color = "white")}
            >
              {"+54" + provincia.numeroWhatsapp}
            </a>
          ) : (
            <span>{provincia.numeroWhatsapp}</span>
          )}
        </div>
      )}
      {provincia.telefono !== undefined && (
        <div className="bg-dark text-light rounded text-center p-2 mb-4">
          <span
            className="iconify mr-2 mb-1"
            data-icon="logos:phone"
            data-inline="false"
            data-width="20px"
          ></span>
          <span className="mr-2">
            <b>Telefono:</b>
          </span>
          <span>{provincia.telefono}</span>
        </div>
      )}

      <div className="bg-dark text-light rounded text-center p-2 mb-4">
        <span
          className="iconify mr-2 mb-1"
          data-icon="ant-design:mail-outlined"
          data-inline="false"
        ></span>
        <span>{provincia.mail}</span>
      </div>

      <p className="text-small">{provincia.dias}</p>
      {provincia.diasFinde !== undefined && (
        <p className="text-small">
          Sabado de 9 a 21hs y Domimgo de 9:30 a 15hs.
        </p>
      )}
    </div>
  );
};

export default Contactos;
