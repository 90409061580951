import React, { useState, useEffect } from "react";
import { Animated } from "react-animated-css";
import { Link } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import { estilosProv } from "../../configProvincias";
import { ColMenu, SpanFont } from "../../navBarCF";

const TopNavDesktop = (props) => {
  const fondoSpan =
    estilosProv[process.env.REACT_APP_COD_ORGANIZACION].secundaryColor;
  const [visible, setvisible] = useState(false);
  const testAnim = () => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        $(".ripple").click();
      }
    }
    props.volverAPlataforma();
  };

  useEffect(() => {
    let b = document.getElementById("newGameModal");
    if (b !== null) {
      if (b.className.includes("modal fade show")) {
        setvisible(!visible);
      }
    }
  }, []);

  return !(
    props.height < 500 &&
    ["portrait-primary", "portrait-secondary"].includes(
      window.screen.orientation.type
    )
  ) ? (
    <>
      <DivMenuRapido>
        {props.logueado && (
          <div
            className={
              "row mx-auto p-1 pt-2 pb-2 text-center " +
              process.env.REACT_APP_COD_ORGANIZACION
            }
            style={{ backgroundColor: fondoSpan, width: "100%" }}
          >
            {props.props.estadoPlataforma.estado === "PLATAFORMA" &&
            props.size > 820 &&
            props.height > 500 ? (
              <ColMenu className="col-1 div-q-icon">
                {props.currentPage !== "registrarse" && (
                  <BotonHambur
                    onClick={() => {
                      props.toggleMenu();
                    }}
                    className="navbar-toggler"
                  >
                    <span
                      color={props.colorFont}
                      className={"iconify"}
                      data-icon={"ant-design:menu-outlined"}
                      data-inline="false"
                      data-width="30px"
                    />
                  </BotonHambur>
                )}
              </ColMenu>
            ) : (
              <ColMenu className="col-1">
                {props.currentPage !== "registrarse" && (
                  <Link
                    onClick={() => {
                      props.props.volverAPlataforma();
                    }}
                    to="/home"
                  >
                    <SpanFont
                      color={props.colorFont}
                      className="iconify"
                      data-icon="ant-design:left-outlined"
                      data-width="30px"
                    />
                  </Link>
                )}
              </ColMenu>
            )}{" "}
            <Link
              className={
                "col sm-3 pr-0 pl-0 inicioIphon  " +
                process.env.REACT_APP_COD_ORGANIZACION
              }
              exact={"true"}
              activeclassname="is-active"
              to="/home"
            >
              <div
                className="div-q-icon"
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify q-icon home-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="bytesize:home"
                  data-inline="false"
                  data-width="30px"
                  color={
                    [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
                      ? "black"
                      : "white"
                  }
                ></span>
                <div className="text-small" style={{ marginTop: "-1px" }}>
                  <span
                    className={
                      "home-text q-icon " +
                      process.env.REACT_APP_COD_ORGANIZACION
                    }
                  ></span>
                </div>
              </div>
            </Link>
            <Link
              to="/consultaBilletera"
              onClick={() => {
                testAnim();
              }}
              className="col pr-0 pl-0 sm-3 billeIphon"
            >
              <div className="div-q-icon">
                <span
                  className={
                    "iconify q-icon billetera-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="fa6-solid:money-bill-transfer"
                  data-inline="false"
                  data-width="30px"
                  color={
                    [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
                      ? "black"
                      : "white"
                  }
                ></span>
                <div className="text-small" style={{ marginTop: "-3px" }}>
                  <span
                    className={
                      "billetera-text q-icon " +
                      process.env.REACT_APP_COD_ORGANIZACION
                    }
                  ></span>
                </div>
              </div>
            </Link>
            {/* <div
                className="ripple"
                data-toggle="modal"
                data-target="#newGameModal"
                hidden={props.estado !== "PLATAFORMA"}
                style={{ zIndex: "3", padding: "0 1em", minWidth: "3em" }}
              >
                <div
                  className={
                    "jugar-icon-cont jugar-" +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                >
                  <span
                    style={{ marginTop: "5px" }}
                    className="iconify"
                    data-icon="fluent:lottery-24-regular"
                    data-inline="false"
                    data-width="40px"
                    color= {[42,12].includes(process.env.REACT_APP_COD_ORGANIZACION) ? "black" : "white"} 
                  ></span>
                </div>
              </div>*/}
            <Link
              className="col pr-0 pl-0 sm-3"
              to={
                process.env.REACT_APP_ENABLE_INGRESO_DINERO
                  ? props.estadoPlataforma?.apellido === "Toreto12"
                    ? "/identificadorQRCliente/0"
                    : "/identificadorQRCliente/1"
                  : "/identificadorQRCliente/"
              }
            >
              <div
                className="div-q-icon"
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify q-icon retirar-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="ion:wallet-outline"
                  data-inline="false"
                  data-flip="horizontal"
                  data-width="30px"
                  color={
                    [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
                      ? "black"
                      : "white"
                  }
                ></span>
                <div className="text-small" style={{ marginTop: "-2px" }}>
                  <span
                    className={
                      "retirar-text q-icon " +
                      process.env.REACT_APP_COD_ORGANIZACION
                    }
                  ></span>
                </div>
              </div>
            </Link>
            <Link
              className="col pr-0 pl-0 sm-3 userIphon5"
              to="/misDatosPersonales"
            >
              <div
                className="div-q-icon"
                onClick={() => {
                  testAnim();
                }}
              >
                <span
                  className={
                    "iconify q-icon user-icon " +
                    process.env.REACT_APP_COD_ORGANIZACION
                  }
                  data-icon="bi:person"
                  data-inline="false"
                  data-flip="horizontal"
                  data-width="30px"
                  color={
                    [42, 12].includes(process.env.REACT_APP_COD_ORGANIZACION)
                      ? "black"
                      : "white"
                  }
                ></span>
                <div className="text-small" style={{ marginTop: "-3px" }}>
                  <span
                    className={
                      "user-text q-icon " +
                      process.env.REACT_APP_COD_ORGANIZACION
                    }
                  ></span>
                </div>
              </div>{" "}
            </Link>
          </div>
        )}
      </DivMenuRapido>
    </>
  ) : (
    <></>
  );
};

export default TopNavDesktop;

const BotonVerMenu = styled.button`
  bottom: ${(props) => props.bottom};
  position: fixed;
  z-index: 1;
  background-color: rgb(227, 229, 231);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  left: 0;
`;

const DivMenuRapido = styled.div`
  width: 100%;
  z-index: 2;
`;

const BotonHambur = styled.button`
  margin: 0px !important;
`;
