import React, { useEffect, useState } from "react";
import { DivGeneral } from "../../screens/consultaBilletera";
import { Animated } from "react-animated-css";
import { DivRow } from "../../tools/identificadorQRCliente/PaginaSolicitudRetiro";
import NumberFormat from "react-number-format";
import walletImgMODO from "../../img/wallets/modo.png";
import styled from "styled-components";
import ButtonSalta from "./ButtonSalta";
import $ from "jquery";
import QRCode from "qrcode.react";
import axios from "axios";
import EfectoCargando from "../../tools/EfectoCargando";

const inputStyles = {
  border: "1px solid #D0D5DA",
  fontWeight: "bold",
  textAlign: "center",
  borderRadius: "10px",
  fontSize: "1.2rem",
  padding: "3px",
};

const inputProps = {
  type: "text",
  autoComplete: "off",
  maxLength: 13,
  isNumericString: true,
  decimalScale: 2,
  placeholder: "$ 0,00",
  prefix: "$ ",
  displayType: "input",
  thousandSeparator: ".",
  decimalSeparator: ",",
};

const PENDING = "PENDIENTE";
const PAID = "PAGADO";
const EXPIRED = "EXPIRADO";

const inactiveSessionError = [
  { error: "Su sesión ha cerrado por inactividad" },
];

const DepositMoneySalta = ({
  estadoPlataforma,
  montoConDecimales,
  setmontoConDecimales,
  propDeposito,
  formatearNumeroString,
  cumpleRequisito,
  mensajeErrorWS,
}) => {
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [pendingOrder, setPendingOrder] = useState({ orderId: "", amount: 0 });
  const [qrCode, setQrCode] = useState("");
  const [loadingGenerateOrder, setLoadingGenerateOrder] = useState(false);
  const [loadingCancelOrder, setLoadingCancelOrder] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  const generalError = () => {
    mensajeErrorWS("Error", [
      { error: "Ocurrió un error, por favor intente de nuevo." },
    ]);
  };

  const commonHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
    Authorization: "Bearer " + estadoPlataforma.userToken,
  };

  useEffect(() => {
    estadoPlataforma.logueado && $("#idMontoSolicitudSalta").focus();
  }, []);

  useEffect(() => {
    wallets.length > 0 && setSelectedWallet(wallets[0].id ?? null);
  }, [wallets]);

  const getUserWalletData = () => {
    let closeSession = false;
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_USER_WALLET_DATA;

    fetch(url, {
      method: "GET",
      headers: { ...commonHeaders },
    })
      .then((respPromise) => {
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          closeSession = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (closeSession) {
          mensajeErrorWS("Consulta de billetera", inactiveSessionError, true);
          return;
        }

        if (json) {
          setWallets([{ id: json, name: "MODO", img: walletImgMODO }]);
          generateQR(json); // generate the qr before generate de order to deposit, cuz it needs to be associated to the user
        } else {
          mensajeErrorWS("Error", [
            { error: "No se pudo obtener los datos de la billetera." },
          ]);
        }
      })
      .catch((error) => generalError());
  };

  const getPendingOrder = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GET_PENDING_DEPOSIT_ORDER;

    fetch(url, {
      method: "GET",
      headers: { ...commonHeaders },
    })
      .then((respPromise) => {
        return respPromise.json();
      })
      .then((data) => {
        setPendingOrder({ orderId: data.ordenPendiente, amount: data.importe });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (estadoPlataforma.logueado) {
      getPendingOrder();
      getUserWalletData();
    }
  }, [estadoPlataforma.logueado]);

  useEffect(() => {
    let interval = null;
    if (estadoPlataforma.logueado && pendingOrder.orderId) {
      interval = setInterval(() => {
        getDepositStatus(pendingOrder.orderId);
      }, 1500);
    }
    return () => clearInterval(interval);
  }, [pendingOrder.orderId, estadoPlataforma.logueado]);

  const handleGenerateDeposit = () => {
    let closeSession = false;
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_GENERAR_ORDEN_CARGA;
    setLoadingGenerateOrder(true);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
      body: JSON.stringify({
        identificacion: selectedWallet,
        importeCarga: montoConDecimales,
      }),
    })
      .then((res) => {
        setLoadingGenerateOrder(false);
        if (res.status == process.env.REACT_APP_CODIGO_CERRO_SESION)
          closeSession = true;

        return res.json();
      })
      .then((json) => {
        if (closeSession) {
          mensajeErrorWS("Generación de orden", inactiveSessionError, true);
          return;
        }

        if (json.ordenId) {
          setPendingOrder({
            orderId: json.ordenId,
            amount: montoConDecimales,
          });
        } else {
          mensajeErrorWS("Error", [
            { error: "No se pudo generar la orden de depósito." },
          ]);
        }
      })
      .catch((error) => {
        setLoadingGenerateOrder(false);
        generalError();
      });
  };

  const handleCancelDepositOrder = () => {
    let closeSession = false;
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_USER_CANCEL_DEPOSIT_ORDER;
    setLoadingCancelOrder(true);

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + estadoPlataforma.userToken,
      },
      body: JSON.stringify({ orden: pendingOrder.orderId }),
    })
      .then((res) => {
        setLoadingCancelOrder(false);
        if (res.status == process.env.REACT_APP_CODIGO_CERRO_SESION)
          closeSession = true;

        return res.json();
      })
      .then((json) => {
        if (closeSession) {
          mensajeErrorWS("Cancelación de orden", inactiveSessionError, true);
          return;
        }

        if (json) {
          setPendingOrder({ orderId: "", amount: 0 });
          mensajeErrorWS("Cancelación exitosa", [
            { error: "Orden de depósito cancelada correctamente." },
          ]);
        } else {
          mensajeErrorWS("Error", [
            { error: "No se pudo cancelar la orden de depósito." },
          ]);
        }
      })
      .catch((error) => {
        setLoadingCancelOrder(false);
        generalError();
      });
  };

  const generateQR = (walletId) => {
    const url = process.env.REACT_APP_WS_GENERATE_QR_FOR_DEPOSIT;
    const orgCode = process.env.REACT_APP_COD_ORGANIZACION;

    fetch(`${url}?inst=${orgCode}&billeteraId=${walletId}`, {
      method: "GET",
      headers: { ...commonHeaders },
    })
      .then((respPromise) => {
        return respPromise.json();
      })
      .then((json) => {
        setQrCode(json.qr_data);
      })
      .catch((error) => generalError());
  };

  const getDepositStatus = (orderId) => {
    const url = process.env.REACT_APP_WS_GET_DEPOSIT_STATUS;

    fetch(`${url}?id_orden=${orderId}`, {
      method: "GET",
      headers: { ...commonHeaders },
    })
      .then((respPromise) => {
        return respPromise.json();
      })
      .then((json) => {
        setOrderStatus(json.status);
        if ([PAID, EXPIRED].includes(json.status)) {
          setPendingOrder({ orderId: "", amount: 0 });
        }
        if (json.status === PAID) {
          mensajeErrorWS("Depósito exitoso", [
            { error: "Tu saldo ha sido actualizado." },
          ]);
          window.refreshSaldo();
        }
      })
      .catch((error) => generalError());
  };

  if (!estadoPlataforma.logueado)
    return (
      <DivGeneral className="pageFlow" style={{ position: "relative" }}>
        <NotAllowedDiv onClick={() => $("#loginModal").modal("show")} />
        {/* template when no there is no session */}
        <DepositMoneySaltaTemplate
          propDeposito={propDeposito}
          formatearNumeroString={formatearNumeroString}
        />
      </DivGeneral>
    );

  return (
    <DivGeneral className="pageFlow" style={{ position: "relative" }}>
      <Animated
        animationIn="fadeIn"
        hidden={estadoPlataforma.estado !== "PLATAFORMA"}
      >
        {!pendingOrder.orderId && wallets.length > 0 && (
          <div className="container">
            {wallets.length > 1 ? (
              <>
                <DivRow className="row justify-content-center">
                  <WalletContainer>
                    {wallets.map(({ id, img, name }) => (
                      <DivWallet
                        key={id}
                        selected={selectedWallet === id}
                        onClick={() => setSelectedWallet(id)}
                        title={name}
                      >
                        <ImgWallet src={img} alt={name} />
                      </DivWallet>
                    ))}
                  </WalletContainer>
                </DivRow>
                <DivRow className="row justify-content-center">
                  <div className="col-12 col-md-6">
                    <h6>
                      Importe a <span className="m-bold">Ingresar</span>
                    </h6>
                    <NumberFormat
                      {...inputProps}
                      id="idMontoSolicitudSalta"
                      name="fMontoSolicitud"
                      style={inputStyles}
                      onValueChange={(values) => {
                        setmontoConDecimales(values.floatValue);
                      }}
                    />
                  </div>
                </DivRow>
              </>
            ) : (
              <DivRow className="row justify-content-center">
                <WalletContainer className="align-items-center">
                  <DivWallet selected={true} title={wallets[0].name}>
                    <ImgWallet src={wallets[0].img} alt={wallets[0].name} />
                  </DivWallet>
                  <div>
                    <h6>
                      Importe a <span className="m-bold">Ingresar</span>
                    </h6>
                    <NumberFormat
                      {...inputProps}
                      id="idMontoSolicitudSalta"
                      name="fMontoSolicitud"
                      style={inputStyles}
                      onValueChange={(values) => {
                        setmontoConDecimales(values.floatValue);
                      }}
                    />
                  </div>
                </WalletContainer>
              </DivRow>
            )}

            {propDeposito !== undefined && (
              <DivRow className="row justify-content-center">
                <div
                  className="col-12"
                  style={
                    cumpleRequisito()
                      ? { fontSize: "12px" }
                      : { fontSize: "12px", color: "red" }
                  }
                >
                  <h6>
                    Desde: ${" "}
                    {formatearNumeroString(
                      propDeposito.minTrans?.toString() ?? ""
                    )}{" "}
                    Hasta: ${" "}
                    {formatearNumeroString(
                      propDeposito.maxDia?.toString() ?? ""
                    )}
                  </h6>
                </div>
                <div
                  className="col-12"
                  style={
                    cumpleRequisito()
                      ? { fontSize: "12px" }
                      : { fontSize: "12px", color: "red" }
                  }
                >
                  Múltiplo: $ {propDeposito.mulTrans}
                </div>
              </DivRow>
            )}
            <DivRow className="row justify-content-center">
              <div className="col-12">
                <ButtonSalta
                  variant="success"
                  disabled={
                    !cumpleRequisito() ||
                    !selectedWallet ||
                    loadingGenerateOrder
                  }
                  id="botonGenerarSolicitud"
                  onClick={handleGenerateDeposit}
                >
                  {loadingGenerateOrder ? (
                    <div className="animate-flicker">
                      <b>Cargando</b>
                    </div>
                  ) : (
                    "Ingresar"
                  )}
                </ButtonSalta>
              </div>
            </DivRow>
          </div>
        )}

        {pendingOrder.orderId && qrCode && orderStatus === PENDING && (
          <div className="container">
            <DivRow className="row justify-content-center">
              <Animated animationIn="zoomIn">
                <div
                  style={{
                    fontSize: ".8rem",
                    margin: "0 0 10px 0",
                  }}
                >
                  Escanea el código QR
                </div>
                <QRCode className="mb-1" value={qrCode} />
                {pendingOrder.amount && (
                  <div className="m-bold">
                    <h6 className="mb-0">
                      $ {formatearNumeroString(pendingOrder.amount.toString())}
                    </h6>
                  </div>
                )}
              </Animated>
            </DivRow>
            <DivRow className="row justify-content-center">
              <ButtonSalta
                variant="danger"
                type="button"
                onClick={() => {
                  handleCancelDepositOrder();
                }}
                disabled={loadingCancelOrder}
              >
                {loadingCancelOrder ? (
                  <div className="animate-flicker">
                    <b>Cargando</b>
                  </div>
                ) : (
                  "Cancelar"
                )}
              </ButtonSalta>
            </DivRow>
          </div>
        )}
      </Animated>
    </DivGeneral>
  );
};

export default DepositMoneySalta;

const WalletContainer = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
`;

const DivWallet = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  padding: 3px;

  ${({ selected }) =>
    selected
      ? ` 
      border-color: ${process.env.REACT_APP_MANIFEST_COLOR};
      transform: scale(1.1);`
      : `&:hover {
        transform: scale(1.1);
        border-color: transparent;
      }`}
`;

const ImgWallet = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NotAllowedDiv = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const DepositMoneySaltaTemplate = (props) => {
  const data = props?.propDeposito;
  return (
    <Animated animationIn="fadeIn">
      <div className="container">
        <DivRow className="row justify-content-center">
          <WalletContainer className="align-items-center">
            <DivWallet selected={true} title={"MODO"}>
              <ImgWallet src={walletImgMODO} alt={"modo image"} />
            </DivWallet>
            <div>
              <h6>
                Importe a <span className="m-bold">Ingresar</span>
              </h6>
              <NumberFormat {...inputProps} style={inputStyles} readOnly />
            </div>
          </WalletContainer>
        </DivRow>
        <DivRow className="row justify-content-center">
          <div className="col-12" style={{ fontSize: "12px" }}>
            <h6>
              Desde: ${" "}
              {props.formatearNumeroString(data?.minTrans?.toString() ?? "0")} Hasta:
              $ {props.formatearNumeroString(data?.maxDia?.toString() ?? "0")}
            </h6>
          </div>
          <div className="col-12" style={{ fontSize: "12px" }}>
            Múltiplo: $ {data?.mulTrans ?? "0"}
          </div>
        </DivRow>
        <DivRow className="row justify-content-center">
          <div className="col-12">
            <ButtonSalta variant="success">Ingresar</ButtonSalta>
          </div>
        </DivRow>
      </div>
    </Animated>
  );
};
